import React, { useState, useEffect, useCallback } from 'react';
import { Divider, Chip, Popover, Snackbar, Alert, Stack, Button, Stepper, Step, StepLabel, Tooltip } from '@mui/material';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { IMAGES_PATH, LOTS, LOTS_EDIT } from '../../../constants/paths';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BasicFormStepByStep from '../../../Components/Generic/basicFormStepByStep';
import QRCode from 'qrcode.react';
import { config } from '../../../Config/index';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { QrCode } from '@mui/icons-material';
import { getLotsByBox } from '../../../services/boxes';
import { getObject, putObjectsMassive } from '../../../services/object';
import eventFormatter from '../../../Helpers/eventFormatter';
import { getAssetsLotData } from '../../../services/lots';
import GridMassiveEdit from '../../../Components/FileUploader/GridMassiveEdit'
import MassiveSummary from '../../../Components/FileUploader/MassiveSummary';
import { calculateLabelDates } from '../../../Utils/gridDefaultValues';
import ModalErrorResponse from '../../../Components/Feature/ModalErrorResponse';
import ModalErrorInfo from '../../../Components/Feature/ModalErrorInfo';
import { dateSanitizer } from '../../../Helpers/dateFormater';

export default function LotsTable(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { selectedAsset, selectedBox, getSessionDataByKey, userData } = useGlobalContext();
  const [parentType] = useState('box');
  const [menuActionSelectedRow, setMenuActionSelectedRow] = useState(null);
  const [fieldsEvents, setFieldsEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedAssetsInLot, setSelectedAssetsInLot] = useState(null);
  const [showEventDialog, setShowEventDialog] = useState(false);
  const [anchorQr, setAnchorEl] = useState(null);
  const [anchorParams, setAnchorParams] = useState()
  const [lotData, setLotData] = useState({})
  const [rows, setRows] = useState([])
  const [models, setModels] = useState({})
  const [columns, setColumns] = useState([])
  const [pageSize, setPageSize] = React.useState(5);
  const [activeStep, setActiveStep] = useState(0);
  const [isToastOpen, setIsToastOpen] = useState(false)
  const [modalErrorInfo, setModalErrorInfo] = useState(false);
  const [listErrors, setListErrors] = useState([]);
  const [dataList, setDataList] = useState()
  const timeZone = userData?.userData?.timezone;
  const steps = [
    t("stepByStep.massive.spreadsheet.eventStep.loadData"),
    t("stepByStep.massive.spreadsheet.eventStep.manualSummary")];

  const updateMenuActionSelectedRow = useCallback((params) => {
    let args = {
      pk: params.id,
      mode: 'update',
      objectTypeSubType: selectedAsset.id,
      globalModel: selectedAsset.id,
      parentType: parentType,
      parentTypeSubType: selectedBox.subtype,
      parentId: selectedBox.id,
    };

    history.push({
      pathname: LOTS_EDIT,
      state: { refererContext: args },
    });
  }, [])

  useEffect(() => {
    getLots()
  }, [selectedBox.id])

  useEffect(() => {
    if (selectedAsset.id !== -1) {
      let models = JSON.parse(localStorage.getItem('SESSION_DATA'))?.models[selectedAsset.id]

      setModels(models)
      setFieldsEvents(eventFormatter(models, "LOT"));
    }
  }, [selectedAsset.id]);

  const getLots = () => {
    const models = JSON.parse(localStorage.getItem('SESSION_DATA'))?.models[selectedAsset.id]
    
    getLotsByBox(selectedBox.id)
      .then(res => {
        let cols = [
          {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            getActions: (params) => [
              (models?.QrHide === undefined || !models?.QrHide) 
                ? <GridActionsCellItem
                    label='QrActions'
                    icon={<QrCode />}
                    onClick={(e) => openQrPopover(e, params)}
                  />
                : null,
              <GridActionsCellItem
                label={t("component.popoverOption.edit")}
                disabled={!userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("TAG_EDIT")}
                onClick={() => updateMenuActionSelectedRow(params)}
                showInMenu
              />,
              ...res.data?.events.map((event, index) => {
                return <GridActionsCellItem
                  key={index}
                  label={event.name}
                  disabled={event.eventType === 1000 
                    ? !(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("ASSET_TRANSFER"))
                    : !(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("ASSET_EDIT"))
                  }
                  onClick={() => {
                    rowMenuActionshandleClick(event, params);
                    setShowEventDialog(true);
                    setSelectedEvent(event)
                    props.setToastMsg(null)
                  }}
                  showInMenu
                />;
              })
            ].filter(a => a)
          }
        ]

        let defaultCols = setTooltips(res.data?.defaultColumns)
        let rows = res.data?.rows.map( a => {
          a.timsta = dateSanitizer(a.timsta)
          return a
        }) 
        setRows(rows)
        setColumns([...cols,...defaultCols])
      })
    .catch(
      err => console.log('error getLotsByBox', err)
    )
  }

  const setTooltips = (defaultCols) => {
    return defaultCols.map((a) => ( {
      ...a,
      renderCell: (params) => (
        params.value?.length * 10 > a.width ?
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
        : <span>{params.value}</span>
      ),
    }));
  };

  const getAssetsInLot = async (lotId) => {
    getAssetsLotData(lotId)
      .then(res => {
        if(res.status === 200){
          const assetList = res.data.map(asset => {
            return {id: asset.id_asset, ...asset.asset_data}
          })

          setSelectedAssetsInLot(assetList)
        }
      })
    .catch(err => console.log('error getAssetsInLot', err))
  }

  const openQrPopover = (event, params) => {
    getObject('lot', params.id)
      .then(res => setLotData(res.data.values))
      .catch(err => console.log('err getObject', err))
    setAnchorParams(params)
    setAnchorEl(event.currentTarget);
  };

  const closeQrPopover = () => {
    setAnchorEl(null);
  };

  const rowMenuActionshandleClick = (event, row) => {
    setMenuActionSelectedRow(row);
    getAssetsInLot(row.id)
    setAnchorEl(event.currentTarget);
  };

  const downloadQR = () => {
    let canvas = document.getElementById('generated_qr');
    let pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrProduktoTrupo_" + anchorParams?.id + `.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

  };

  const downloadQRIdentecoTrupo = () => {
    let canvas = document.getElementById('generated_qr_identeco_trupo');
    let pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrIdentecoTrupo_" + anchorParams?.id + `.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadQRSVG = (idSVG) => {
    let svg = document.getElementById(idSVG);
    let serializer = new XMLSerializer();

    let source = serializer.serializeToString(svg);
    if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    let svgUrl = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

    let downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = anchorParams?.id + `.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const openQrProductoTrupo = () => {
    window.open(lotData?.qr_link_produkto_trupo, '_blank')?.focus();
  };

  const openQrIdentecoTrupo = () => {
    window.open(lotData?.qr_link_identeco_trupo, '_blank')?.focus();
  };

  const copyQrProductoTrupoOnClipboard = () => {
    navigator.clipboard.writeText(lotData?.qr_link_produkto_trupo);
  }

  const copyQrIdentecoTrupoOnClipboard = () => {
    navigator.clipboard.writeText(lotData?.qr_link_identeco_trupo);
  }

  const onModalErrorInfoClosed = () => {
    setModalErrorInfo(false);
  }

  const handleFinalSubmit = () => {
    let dataListTemp = [];
    dataList.forEach(data => {
      let dataTemp = { ...data };
      delete dataTemp.id;
      let dataTempNoValuesFilter = {};
      for (let key of Object.entries(dataTemp)) {
        if (dataTemp[key[0]] != null && selectedEvent.fields.some(field => field.name === key[0])) {
          dataTempNoValuesFilter[key[0]] = dataTemp[key[0]];
        }
      }
      dataListTemp.push({ ...dataTempNoValuesFilter, pk: data.id });
    });

    let attr =
      getSessionDataByKey('models')[selectedAsset.id].attributes;

    let dataListTempLabels = [];
    dataListTemp.forEach((value, index) => {
      let labelsTemp = {};
      Object.entries(value).forEach((it) => {
        const rowAttr = attr.find(
          (x) =>
            x.name === it[0] &&
            (x.type === 'select' || x.type === 'date' || x.type === 'dateTime' || x.type === 'multiselect')
        );
        if (rowAttr) {
          if (rowAttr.type === 'select') {
            let selectedItem = rowAttr.values.find((i) => i.key === it[1]);
            if (selectedItem) {
              labelsTemp[rowAttr.name] = selectedItem.label;
            }
          }
          if (rowAttr.type === 'multiselect') {
            let selectedItemLabels = [];
            it[1].split(',').forEach((i) => {
              let item = rowAttr.values.find((x) => x.key === i);
              if (item) {
                selectedItemLabels.push(item.label);
              }
            });
            labelsTemp[rowAttr.name] = selectedItemLabels.join(',');
          }
          if (rowAttr.type === 'date' || rowAttr.type === 'dateTime') {
            const { dateLabel, dateValue } = calculateLabelDates(
              it[1],
              timeZone
            );
            labelsTemp[rowAttr.name] = dateLabel;
            dataListTemp[index][it[0]] = dateValue;
          }
        }
        if (it[0] === '_eventDate_') {
          const { dateLabel, dateValue } = calculateLabelDates(it[1], timeZone);
          labelsTemp[it[0]] = dateLabel;
          dataListTemp[index][it[0]] = dateValue;
        }
      });
      dataListTempLabels.push(labelsTemp);
    });

    let dataObj = {
      values: dataListTemp,
      labels: dataListTempLabels,
      headerValues: {},
      action: 'update',
      pk: null,
      objectType: 'asset',
      pkObjectType: selectedAsset.id,
      pkReferer: null,
      holder: selectedBox.id,
      temporalDetailParentId: -1,
      globalModel: selectedAsset.id,
      actionContext: { context: 'massive_manual_form', eventType: selectedEvent.eventType },
    };

    putObjectsMassive(dataObj)
    .then(res => {
      setShowEventDialog(false)
    })
    .catch(err => {
      if (err.response && err.response.data) {
        const errorData = err.response.data;
        console.log("ERROR", err);
        setListErrors(errorData)
        setModalErrorInfo(true)
      } else {
        console.log("ERROR", err);
        setModalErrorInfo(true)
      }
    });
    setActiveStep(0);
  };

  const handleSubmitMassiveEdit = (list) => {
    setDataList(list);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderPopOverQrActions = () => (
    <Popover
      id='qrPopover'
      open={Boolean(anchorQr)}
      anchorEl={anchorQr}
      onClose={closeQrPopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <>
        <Divider >
          <Chip label="Producto trupo" color='primary' icon={
            <QRCode
              size={1000}
              style={{ maxWidth: "20px", maxHeight: "20px" }}
              value={lotData?.qr_link_produkto_trupo}
              renderAs="png"
              includeMargin id={"generated_qr"}
              imageSettings={{ src: `${IMAGES_PATH}/new_origino_qr.png`, excavate: true, width: "225", height: "225" }}
              level={'H'}
            />
          }
          />
        </Divider>
        <GridActionsCellItem
          label="Abrir QR"
          onClick={openQrProductoTrupo}
          showInMenu
        />
        <GridActionsCellItem
          label="Copiar QR"
          onClick={copyQrProductoTrupoOnClipboard}
          showInMenu
        />
        <GridActionsCellItem
          label="Descargar QR PNG (standard)"
          onClick={downloadQR}
          showInMenu
        />
        <GridActionsCellItem
          label="Descargar QR SVG (avanzado)"
          onClick={() => downloadQRSVG('generated_qr_producto_trupo_svg_xl')}
          showInMenu
        />
      </>
      <Divider >
        <Chip label="Identeco trupo" color='primary'
          icon={
            <QRCode
              size={1000}
              style={{ maxWidth: "20px", maxHeight: "20px" }}
              value={lotData?.qr_link_identeco_trupo}
              renderAs="canvas"
              includeMargin id={"generated_qr_identeco_trupo"}
              imageSettings={{ src: `${IMAGES_PATH}/new_origino_qr.png`, excavate: true, width: "225", height: "225" }}
              bgColor="#ffffffff"
              level={'H'}
            />
          }
        />
      </Divider>
      <GridActionsCellItem
        label="Abrir QR"
        onClick={openQrIdentecoTrupo}
        showInMenu
      />
      <GridActionsCellItem
        label="Copiar QR"
        onClick={copyQrIdentecoTrupoOnClipboard}
        showInMenu
      />
      <GridActionsCellItem
        label="Descargar QR PNG (standard)"
        onClick={downloadQRIdentecoTrupo}
        showInMenu
      />
      <GridActionsCellItem
        label="Descargar QR SVG (avanzado)"
        onClick={() => downloadQRSVG('generated_qr_identeco_trupo_svg_xl')}
        showInMenu
      />
    </Popover>
  )


  return (
    <>
      {renderPopOverQrActions()}
      {rows &&
        <div style={{ width: '100%', height: 400 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            checkboxSelection={true}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 30, 50]}
            pagination
          />
        </div>
      }
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={() => setIsToastOpen(false)}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Operacion Exitosa
        </Alert>
      </Snackbar>

      {selectedEvent &&
        <Dialog open={showEventDialog} onClose={() => { }}
          fullWidth={true}
          maxWidth={selectedEvent.modelExcel === true ? '' :'lg'}
        >
          {listErrors.length > 0 ? (
            <ModalErrorResponse
              open={modalErrorInfo}
              toggleModal={() => {
                onModalErrorInfoClosed();
              }}
              modalText={t('component.comErrorList')}
              listErrors={listErrors}
            />
          ) : (
            <ModalErrorInfo
              open={modalErrorInfo}
              toggleModal={() => {
                onModalErrorInfoClosed();
              }}
              modalText={t("component.comError")}
            />
          )}
          <DialogTitle>{`Evento: ${selectedEvent.name}`}</DialogTitle>
          <DialogContent style={{ minHeight: "400px" }}>
            { selectedAssetsInLot?.length > 0 && (
              <>
                { (selectedEvent.modelExcel === true) ? (
                  <>
                    <Stepper activeStep={activeStep} sx={{paddingBottom:3}}>
                      {steps.map((label) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    {
                      activeStep === 0 && (
                        <GridMassiveEdit
                          mode={'Edit'}
                          data={selectedAssetsInLot}
                          entityMode={'asset'}
                          enableEventDate={true}
                          eventType={selectedEvent.eventType}
                          pairColumns={[]}
                          excelUploadId={-1}
                          filterAttributeModel={selectedEvent.fields}
                          onSubmit={handleSubmitMassiveEdit}
                          onSetAmountErrorsInitial={(err) => { console.log('err', err) }}
                          globalModel={selectedAsset.id}
                          pkObjectType={selectedAsset.id}
                          onDataChange={() => {}}
                          manualUpload={true}
                          handleCancel={() => { setShowEventDialog(false) }}
                        />
                      )
                    }
                    {
                      activeStep === 1 && (
                        <MassiveSummary
                          showControls={true}
                          dataList={dataList}
                          globalModel={selectedAsset.id}
                          onFinalSubmit={handleFinalSubmit}
                          amountErrorsInitial={0}
                          handleBack={handleBack}
                        />
                      )
                    }
                  </>
                ) : (
                  <BasicFormStepByStep
                    redirectOnErrorPath={LOTS}
                    bindNullWithDefault={true}
                    showStepTitle={false}
                    showSectionTitle={false}
                    sectionAccordionMode={false}
                    fieldColSize={12}
                    formMode={'vertical'}
                    saveFunc={() => {}}
                    show={showEventDialog}
                    onCanceled={() => { setShowEventDialog(false) }}
                    onUpdated={(res) => { setShowEventDialog(false); res && setIsToastOpen(true); getLots()}}
                    withDefault={false}
                    mode={'update'}
                    pk={menuActionSelectedRow?.id}
                    pkObjectType={selectedAsset.id}
                    pkReferer={null}
                    globalModel={selectedAsset.id}
                    objectType={'lot'}
                    objectTypeBindForm={'lot'}
                    parentType={parentType}
                    parentTypeSubType={selectedBox.subtype}
                    parentId={selectedBox.id}
                    count={0}
                    showCancelButton={true}
                    cancelButtontext={"Cancelar"}
                    eventType={selectedEvent.eventType}
                    fields={fieldsEvents[selectedEvent.eventType]}
                    setToastMsg={props.setToastMsg}
                    assetsInLot={selectedAssetsInLot?.map(ail => ail.id)}
                    form={
                      {
                        render: true,
                        config: selectedEvent.form
                          ?
                          [
                            ...selectedEvent.form.config,
                            (selectedEvent.isTransfer 
                              // || !models.events.find(e => e.eventType === selectedEvent.eventType)?.eventWithoutCommentAndDate
                            ) ? {
                              step: 2,
                              title: "Fecha del Evento",
                              sections: [
                                {
                                  section: 1,
                                  title: '-',
                                  fields: fieldsEvents[selectedEvent.eventType].map(
                                    (field) => {
                                      if ((field.name === '_eventDate_' && field.flagHide === false) || field.name === '_eventComment_' || field.name === '_transferLot_') {
                                        return field.name
                                      } else {
                                        return null
                                      }
                                    }
                                  )
                                }
                              ]
                            } : null,
                          ].filter(f => f !== null)
                          :
                          [
                            {
                              step: 1,
                              title: "Atributos del Evento",
                              sections: [
                                {
                                  section: 1,
                                  title: '-',
                                  fields: fieldsEvents[selectedEvent.eventType].map(
                                    (field) => {
                                      if (field.name !== '_eventDate_' && field.name !== '_eventComment_' && field.name !== '_transferLot_') {
                                        return field.name
                                      } else {
                                        return null
                                      }
                                    }
                                  )
                                }
                              ]
                            },
                            (selectedEvent.isTransfer 
                              // || !models.events.find(e => e.eventType === selectedEvent.eventType)?.eventWithoutCommentAndDate
                            ) ? {
                              step: 2,
                              title: "Fecha del Evento",
                              sections: [
                                {
                                  section: 1,
                                  title: '-',
                                  fields: fieldsEvents[selectedEvent.eventType].map(
                                    (field) => {
                                      if ((field.name === '_eventDate_' && field.flagHide === false) || field.name === '_eventComment_' || field.name === '_transferLot_') {
                                        return field.name
                                      } else {
                                        return null
                                      }
                                    }
                                  )
                                }
                              ]
                            } : null,
                          ].filter(f => f !== null)
                      }
                    }
                    submitExternalCallBack={
                      (selectedEvent.isTransfer === true && false)
                        ?
                        (args) => { }
                        :
                        null}
                  />
                )}
              </>
            )}
          </DialogContent>
        </Dialog>
      }

      {/* Es necesario crear el qr para luego poder exportar el svg */}
      <div style={{ display: "none" }}>
        <QRCode
          size={1000}
          value={lotData?.qr_link_produkto_trupo}
          renderAs="svg"
          bgColor="#ffffffff"
          includeMargin
          id={"generated_qr_producto_trupo_svg_xl"}
          imageSettings={{ src: `${config.reactAppUrl}images/new_origino_qr.png`, excavate: true, width: "225", height: "225" }}
          level={'H'}
        />
        <QRCode
          size={1000}
          value={lotData?.qr_link_identeco_trupo}
          renderAs="svg"
          bgColor="#ffffffff"
          includeMargin
          id={"generated_qr_identeco_trupo_svg_xl"}
          imageSettings={{ src: `${config.reactAppUrl}images/new_origino_qr.png`, excavate: true, width: "225", height: "225" }}
          level={'H'}
        />
      </div>
    </>
  );
}

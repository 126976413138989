import instance from '../Config/axios';

export const fetchTenantTheme = async (tenant) => {

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const url = `tenants/gettheme/${tenant}`;

  try {
    const response = await instance.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Error fetching tenant theme:', error);
    throw error;
  }
};

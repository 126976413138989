import { calculateWarrantyExpirationDate } from "./calculateWarrantyExpirationDate";

export const defaultValueFunctions = (asset, attributes) => {

  const assetWithDefaultValueFunctions = { ...asset };

  for (const attribute of attributes) {
    if (attribute.defaultValueFunction === "warrantyExpirationDateDefaultValue") {
      const existingValue = assetWithDefaultValueFunctions[attribute.name];
      assetWithDefaultValueFunctions[attribute.name] =
        existingValue ||
        calculateWarrantyExpirationDate(assetWithDefaultValueFunctions);
    }
  }
  return assetWithDefaultValueFunctions;
};

import { Autocomplete, Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ApiFetch } from '../../Utils/ApiFetch';

export const LinkAssetOracleModal = ({
    action, 
    setOpenActionModal, 
    assetDataRow, 
    globalContext,
    selectedAsset,
    selectedBox,
    setModalError,
    setMessageError,
    searchAssetsWithFilters,
    oracleInModal
}) => {
    const { t } = useTranslation();
    const [rankingSelected, setRankingSelected] = useState(null);
    const [loadingSaveOracle, setLoadingSaveOracle] = useState(false);

    const [oraclesRanking, setOraclesRanking] = useState({name: '', values:[]})
    const [loadingOraclesRanking, setLoadingOraclesRanking] = useState(false);

    useEffect(() => {
        if(oracleInModal && oracleInModal !== oraclesRanking.name) {
            setLoadingOraclesRanking(true)
            ApiFetch(
            `oracles/idsOracles/${selectedBox.id}/${oracleInModal}`,
            globalContext,
            true,
            async (res) => {
                if (res.status === 200) {
                const data = await res.json();
                setOraclesRanking({name: oracleInModal, values: Array.isArray(data.values) ? data.values : []})
                }
                setLoadingOraclesRanking(false)
            },
            (error) => {
                console.log('error oracles', error);
                setLoadingOraclesRanking(false)
            },
            {
                method: 'GET',
            }
            );
        }
    },[globalContext, selectedAsset.id, selectedBox.id, oracleInModal, oraclesRanking.name])
    
        
    const linkAssetOracle = () => {
        setLoadingSaveOracle(true)
        const oracleData = oraclesRanking.values.find(rank => rank.id === rankingSelected)
        const valueTemp = {
            ...oracleData,
            pk: assetDataRow.id
        }

        ApiFetch(
            `oracles/saveOracle/${selectedBox.id}/${oraclesRanking.name}/${selectedAsset.id}`,
            globalContext,
            true,
            async (res) => {
              if (res.status === 200) {
                toast.success(t('component.linkOraclesSuccess'), {
                  className: 'toast-message-body',
                  progressClassName: 'toast-message-progress',
                  position: 'bottom-left',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              setLoadingSaveOracle(false)
              setOpenActionModal(false)
              searchAssetsWithFilters()
            },
            async (error) => {
              const result = await error.json()
              console.log('error oracles', error);
              setModalError(true)
              setMessageError({message: result.error, data: result.data})
              setLoadingSaveOracle(false)
            },
            {
              method: 'POST',
              body: JSON.stringify(valueTemp)
            }
        );
    }

    const unlinkAssetOracle = () => {
        setLoadingSaveOracle(true)
        ApiFetch(
            `oracles/unlinkOracle/${selectedBox.id}/${selectedAsset.id}/${assetDataRow.id}`,
            globalContext,
            true,
            async (res) => {
              if (res.status === 200) {
                toast.success(t('component.unlinkOraclesSuccess'), {
                  className: 'toast-message-body',
                  progressClassName: 'toast-message-progress',
                  position: 'bottom-left',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              setLoadingSaveOracle(false)
              setOpenActionModal(false)
              searchAssetsWithFilters()
            },
            async (error) => {
              const result = await error.json()
              console.log('error oracles', error);
              setModalError(true)
              setMessageError({message: result.error})
              setLoadingSaveOracle(false)
            },
            {
              method: 'PUT'
            }
        );
    }

    return (
        <>
        {action === 'link' ? (
            <Grid 
                sx={{
                    padding:'1.5rem',
                    width:'500px'
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={()=>setOpenActionModal(false)}
                    sx={{
                        position: 'absolute',
                        right: '2px',
                        top: '2px',
                    }
                }>
                    <CloseIcon/>
                </IconButton>
                <Grid xs={12} sx={{marginBottom:2}}>
                    <Typography 
                        textAlign={'center'}
                        variant="h3" 
                        component="p"
                    >   
                        {t(`assetsoracles.actions.${action}`)}
                    </Typography>
                </Grid>
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            label={t(`assetsoracles.inputs.CurrentProviderId`)}
                            sx={{ width: '100%', mr: 2 }}
                            disabled={true}
                            name={'id-provider-label'}
                            value={assetDataRow.id_stock_provider ?? ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={oraclesRanking?.values ? oraclesRanking.values.map((option) => option.id) : []}
                            value={rankingSelected}
                            onChange={(event, newValue) => {
                                setRankingSelected(newValue)
                            }}
                            disabled={loadingSaveOracle}
                            loading={loadingOraclesRanking}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    size="small"
                                    label={t(`assetsoracles.inputs.NewProviderId`)}
                                    value={rankingSelected}
                                    onChange={(val) => setRankingSelected(val.target.value)}
                                />
                            }
                        />
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{marginTop: 2, marginRight: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        variant="contained"
                        disabled={loadingSaveOracle || !rankingSelected}
                        onClick={() => linkAssetOracle()}
                    >
                        {t('assetsoracles.button.save')}
                    </Button>
                </Grid>
            </Grid>
        ) : (
            <Grid 
                sx={{
                    padding:'1.5rem',
                    width:'500px'
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={()=>setOpenActionModal(false)}
                    sx={{
                        position: 'absolute',
                        right: '2px',
                        top: '2px',
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <Grid xs={12} sx={{marginBottom:2}}>
                    <Typography 
                        textAlign={'center'}
                        variant="h3" 
                        component="p"
                    >
                        {t(`assetsoracles.actions.${action}`)}
                    </Typography>
                </Grid>
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            size="small"
                            label={t(`assetsoracles.inputs.CurrentProviderId`)}
                            sx={{ width: '100%', mr: 2 }}
                            disabled={true}
                            name={'id-provider-label'}
                            value={assetDataRow.id_stock_provider ?? ''}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            disabled={loadingSaveOracle}
                            onClick={()=>unlinkAssetOracle()}
                            sx={{
                                backgroundColor: '#d32f2f',
                                color: 'white',
                                '&:hover': {
                                backgroundColor: '#b71c1c',
                                },
                            }}
                        >
                            {t('assetsoracles.button.unlink')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )}
        </>
    )
}
import { useState, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { typography, breakpoints } from "../Layout/theme/commonTheme";
import { fetchTenantTheme } from "../services/tenant";
import defaultSignInTheme  from "../pages/SignIn/signInTheme";


const useSignInTheme = () => {
  const [signInTheme, setSignInTheme] = useState(null);

  const loadDynamicSignInTheme = async () => {
    const host = window.location.hostname;
    // Para testear descomentar el hostDos y probar con app o mtr
    /* const hostDos = 'mtr.dev.origino.io/' */
    const subdomain = host.split(".")[0];

    if (subdomain !== "app") {
      try {
        const themeData = await fetchTenantTheme(subdomain);
        if (themeData.name === subdomain) {
          const themeContent = themeData.theme.theme;
          const dynamicSignInTheme = createTheme({
            ...themeContent.signInTheme,
            typography,
            breakpoints,
          });
          setSignInTheme(dynamicSignInTheme);
        }
      } catch (error) {
        setSignInTheme(defaultSignInTheme);
        console.error("Error fetching sign-in theme:", error);
      }
    }
  };

  useEffect(() => {
    loadDynamicSignInTheme();
  }, []);

  return signInTheme;
};

export default useSignInTheme;

import React, { useEffect, useState } from 'react';
import TextMaskedField from './TextMaskedField';

function MaskedField({
  disabled,
  error,
  label,
  masks,
  name,
  onChange,
  value,
  sx,
  size,
}) {
  const [activeMask, setActiveMask] = useState('');

  useEffect(()=> {
    checkSimilarity(value)
  },[])

  const checkSimilarity = (value) => {
    const cleanedValue = value?.replace(/_/g, '');
    if (!cleanedValue) return setActiveMask('')

    const masksMatched = masks.filter(({ regexToSelectMask }) => {
      return new RegExp(regexToSelectMask).test(cleanedValue);
    });


    if (activeMask !== masksMatched?.[0]?.mask) {
      let mask = null

      if (masksMatched.length > 1 && !!activeMask) {
        mask = ''
      } else if (masksMatched.length === 1) {
        mask = masksMatched[0]?.mask
      }

      if (mask) {
        setActiveMask(mask);
        onChange(name, cleanedValue);
      }  
    }
  }

  const handleAccept = (value) => {
    const cleanedValue = value.replace(/_/g, '');
    onChange(name, cleanedValue);
  };

  const defaultProps = {
    disabled,
    error,
    label,
    mask: activeMask,
    name,
    sx,
    onAccept: handleAccept,
    onChange: (e) => checkSimilarity(e.target.value),
    value: value || '',
    size,
  };

  return <TextMaskedField {...defaultProps} />;
}

export default MaskedField;

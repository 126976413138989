import React from 'react';
import { Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { ThemeProvider } from '@mui/material/styles';
import './theme/global.scss';
import GlobalContextProvider from '../Contexts/globalContext';
import useThemeContext from '../Hooks/useThemeContext';

import {
  HOME,
  ASSETS,
  ASSETS_NEW,
  ASSETS_EDIT,
  BOXES,
  BOXES_NEW,
  BOXES_EDIT,
  COMPANY_ALL,
  COMPANY_NEW,
  COMPANY_EDIT,
  LOTS,
  LOTS_NEW,
  LOTS_EDIT,
  ASSETS_FILE_LOAD,
  TRANSFER_LOAD,
  TRANSFER_BOX,
  BOXES_ALL,
  ASSETS_MANUAL_LOAD,
  ASSETS_MASSIVE_EDIT,
  USER_DELEGATIONS_ALL,
  USER_DELEGATIONS_COMPANY_ALL,
  USER_DELEGATIONS,
  EVENTS_MASSIVE,
  EVENTS_MASSIVE_MANUAL,
  TRANSFERS_MASSIVE,
  TRANSFERS_MASSIVE_MANUAL,
  USER_NEW,
  USER_EDIT,
  ACTIVITIES,
  LOGICAL_DELETED_ASSETS,
  REPORTS,
  QR_TEMPLATES,
  QR_IDENTECO,
  QR_IDENTECO_TRUPO,
  TRANSFERS,
  EVENTS,
  REQUIRED_FIELDS_ERROR,
  TRANSFERRED_ASSETS,
  QRS_METRICS,
  QRS_LOGS,
  ASSETS_ORACLES,
  NEW_ASSETS_ORACLES
} from '../constants/paths';

import { TransferBox, BaseUploadContainerTransfers, LoadTransfer, Transfers } from '../pages/Transfers';
import { Lots, CargaLotes } from '../pages/Lots';
import { Activos, CargaActivos, BaseUploadContainerAssets } from '../pages/Assets';
import { Reports } from '../pages/Reports';
import { QrTemplatesList, QrIdentecoEdit, QrIdentecoTrupoEdit } from '../pages/Qr';
import { Boxes, BoxesAll, StepByStepFacilityEdit, StepByStepFacilityNew } from '../pages/Boxes'
import { Activities } from '../pages/Activities'
import { CompanyAll, StepByStepCompanyEdit, StepByStepCompanyNew } from '../pages/Companies'
import { BaseUploadContainerEvents, Events, MassiveEvents } from '../pages/Events';
import { UserDelegations, UserDelegationsAll, UserDelegationsCompanyAll } from '../pages/User';
import { LogicalDeletedAssets } from '../pages/LogicalDeletedAssets';
import { Logout } from '../pages/Access'
import { StepByStepRegistroUser, StepByStepUserEdit } from '../pages/SignIn';
import RequiredFieldsError from '../pages/Errors/RequiredFieldsError';

import PrivateRoute from '../Components/Router/PrivateRoute';
import SidebarContainer from './containers/SidebarContainer';
import TransferredAssets from '../pages/TransferredAssets/TransferredAssets';
import QRsMetrics from '../pages/QrsMetrics/QrsMetrics';
import QRsLogs from '../pages/QrsMetrics/QrsLogs';
import AssetsOracles from '../pages/AssetsOracles/AssetsOracles';
import NewAssetsOracles from '../pages/AssetsOracles/NewAssetsOracles';

function App() {
  let { activeTheme } = useThemeContext();

  return (
    <GlobalContextProvider>
      <ThemeProvider theme={activeTheme}>
        <SidebarContainer>
          <Switch>
            <PrivateRoute path={REQUIRED_FIELDS_ERROR} component={RequiredFieldsError} exact />

            <PrivateRoute path={ACTIVITIES} component={Activities} exact />
            <PrivateRoute path={HOME} component={Activos} exact />

            <PrivateRoute path={ASSETS} component={Activos} exact />
            <PrivateRoute path={ASSETS_EDIT} component={CargaActivos} exact />
            <PrivateRoute path={ASSETS_NEW} component={CargaActivos} exact />
            <PrivateRoute path={ASSETS_FILE_LOAD} component={BaseUploadContainerAssets} exact />
            <PrivateRoute path={ASSETS_MANUAL_LOAD} component={BaseUploadContainerAssets} exact />
            <PrivateRoute path={ASSETS_MASSIVE_EDIT} component={BaseUploadContainerAssets} exact />

            <PrivateRoute path={BOXES} component={Boxes} exact />
            <PrivateRoute path={BOXES_ALL} component={BoxesAll} exact />
            <PrivateRoute path={BOXES_NEW} component={StepByStepFacilityNew} exact />
            <PrivateRoute path={BOXES_EDIT} component={StepByStepFacilityEdit} exact />

            <PrivateRoute path={COMPANY_NEW} component={StepByStepCompanyNew} exact />
            <PrivateRoute path={COMPANY_EDIT} component={StepByStepCompanyEdit} exact />
            <PrivateRoute path={COMPANY_ALL} component={CompanyAll} exact />

            <PrivateRoute path={LOTS} component={Lots} exact />
            <PrivateRoute path={LOTS_EDIT} component={CargaLotes} exact />
            <PrivateRoute path={LOTS_NEW} component={CargaLotes} exact />

            <PrivateRoute path={USER_NEW} component={StepByStepRegistroUser} exact />
            <PrivateRoute path={USER_EDIT} component={StepByStepUserEdit} exact />

            <PrivateRoute path={USER_DELEGATIONS_ALL} component={UserDelegationsAll} exact />
            <PrivateRoute path={USER_DELEGATIONS_COMPANY_ALL} component={UserDelegationsCompanyAll} exact />
            <PrivateRoute path={USER_DELEGATIONS} component={UserDelegations} exact />

            <PrivateRoute path={EVENTS} component={Events} exact />
            <PrivateRoute path={EVENTS_MASSIVE} component={MassiveEvents} exact />
            <PrivateRoute path={EVENTS_MASSIVE_MANUAL} component={BaseUploadContainerEvents} exact />

            <PrivateRoute path={TRANSFERS} component={Transfers} exact />
            <PrivateRoute path={TRANSFERS_MASSIVE} component={BaseUploadContainerTransfers} exact />
            <PrivateRoute path={TRANSFERS_MASSIVE_MANUAL} component={BaseUploadContainerTransfers} exact />
            <PrivateRoute path={TRANSFER_LOAD} component={LoadTransfer} exact />
            <PrivateRoute path={TRANSFER_BOX} component={TransferBox} exact />

            <PrivateRoute path={TRANSFERRED_ASSETS} component={TransferredAssets} exact />

            <PrivateRoute path={ASSETS_ORACLES} component={AssetsOracles} exact />
            <PrivateRoute path={NEW_ASSETS_ORACLES} component={NewAssetsOracles} exact />

            <PrivateRoute path={QR_TEMPLATES} component={QrTemplatesList} exact />
            <PrivateRoute path={QR_IDENTECO} component={QrIdentecoEdit} exact />
            <PrivateRoute path={QR_IDENTECO_TRUPO} component={QrIdentecoTrupoEdit} exact />

            <PrivateRoute path={QRS_METRICS} component={QRsMetrics} exact />
            <PrivateRoute path={QRS_LOGS} component={QRsLogs} exact />
            
            <PrivateRoute path={LOGICAL_DELETED_ASSETS} component={LogicalDeletedAssets} exact />

            <PrivateRoute path={REPORTS} component={Reports} exact />

            <PrivateRoute path="*" component={Logout} />

          </Switch>
        </SidebarContainer>
      </ThemeProvider >
    </GlobalContextProvider >
  );
}

export default App;

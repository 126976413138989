import React, { useEffect, useState } from 'react';

import { Grid, Card, Button, Stack } from '@mui/material';
import { CardSummaryFilter } from './Cards/CardSummaryFilter';
import { CardDetailedInfo } from './Cards/CardDetailedInfo';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { ApiFetch }  from '../../Utils/ApiFetch';
import { useTranslation } from 'react-i18next';

function MassiveSummary(props) {
  const { t } = useTranslation();
  const {setHeaderTitle,setHeaderSubtitle} = useGlobalContext();
  const globalContext = useGlobalContext()
  const { selectedAsset, selectedBox } = useGlobalContext();
  const [dataListLocal, setDataListLocal] = useState([]);

  const handleFinalSubmit = () => {
    props.onFinalSubmit();
  };

  const handleBack = () => {
    props.handleBack();
  };

  useEffect(()=>{
    setHeaderTitle('stepByStep.massive.spreadsheet.summaryTitle')
    setHeaderSubtitle('stepByStep.massive.spreadsheet.summarySubtitle');
  },[]);

  useEffect(()=>{
    if (props.dataList !== undefined) {

      let attr =
      globalContext.getSessionDataByKey('models')[props.globalModel].attributes;

      let dataListTemp = [...props.dataList];


      let dataListTempLabels = [];
      dataListTemp.map((value, index) => {
        let labelsTemp = {};
        Object.entries(value).map((it) => {
          const rowAttr = attr.find(
            (x) =>
              x.name === it[0] &&
              (x.type === 'select')
          );
          if (rowAttr) {
            if (rowAttr.type === 'select') {
              let selectedItem = rowAttr.values.find((i) => i.key === it[1]);
              if (selectedItem) {
                labelsTemp[rowAttr.name] = selectedItem.label;
              }
            }

          }

        });
        dataListTempLabels.push(labelsTemp);
      });



      setDataListLocal(dataListTempLabels);
    }
  },[props.dataList]);

  useEffect(()=>{
    if (props.dataListPK!=="" && props.dataListPK!==null && props.dataListPK!==undefined) {
      let urlTemp = `objects/get/all/${selectedAsset.id}/asset/${selectedAsset.id}/${selectedBox.id}/0/0`;

      const defaultPagination = { field: 'id', sort: 'desc'}
      let sortModel = [];
      sortModel.push(defaultPagination);

      ApiFetch(
        `${urlTemp}/0/-1/${sortModel[0].field}/${sortModel[0].sort}/all`,
          globalContext,
          true,
              async (body) => {
                let resJson = await body.json()

                let rowsToEvent = [];
                for (let tempRow of resJson.res) {
                  if (JSON.parse(props.dataListPK).includes(tempRow.id.toString())) {
                    rowsToEvent.push(tempRow);
                  }
                }
                setDataListLocal(rowsToEvent)
              },
              error => {

              },
              {
                method: "POST",
                body: JSON.stringify({ 'filters':[]}),
              }
      );
    }

  },[props.dataListPK,selectedAsset.id,selectedBox.id]);

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid container className="mt-2" spacing={1}>
      <Grid item xs={12}>
          <Card className="d-flex align-self-center pt-1 ">
            <CardSummaryFilter dataList={dataListLocal} globalModel={props.globalModel} amountErrorsInitial={props.amountErrorsInitial}/>
          </Card>
      </Grid>
      </Grid>
      <Grid container className="mt-2 d-flex justify-content-center align-items-center">
        <Grid className="d-flex align-self-center">
          <CardDetailedInfo dataList={dataListLocal} globalModel={props.globalModel} />
        </Grid>
      </Grid>
      {(props.showControls) &&
      <Stack className="mt-3 d-flex flex-row justify-content-center align-items-center">
        <Button variant="outlined" className='mr-4' onClick={handleBack}>
            {t("stepByStep.massive.spreadsheet.button.edit")}
        </Button>
        <Button className="ml-4" variant="contained" onClick={handleFinalSubmit}>
            {t("stepByStep.massive.spreadsheet.button.end")}
        </Button>
      </Stack>
      }
    </Grid>
  );
}

export default MassiveSummary;

import { config } from '../Config';
import { icon } from 'leaflet';
import { BOXES_ALL, BOXES_EDIT, BOXES_NEW, BOX_ACTIVATE, BOX_REGISTER,
  COMPANY_ACTIVATE, COMPANY_ALL, COMPANY_EDIT, COMPANY_NEW, COMPANY_REGISTER,
  USER_DELEGATIONS, USER_DELEGATIONS_ALL, USER_DELEGATIONS_COMPANY_ALL, USER_EDIT,
  HOME, EVENTS,TRANSFERS, QR_TEMPLATES, ACTIVITIES, LOTS, LOGICAL_DELETED_ASSETS } from './paths';

export const SNACKBAR_AUTO_HIDE_DURATION = 6000

export const TOAST_TYPE = { INFO: 'info', WARNING: 'warning', ERROR: 'error', SUCCESS: 'success' }

export const ACTIVE_MODEL = {
  vacaPn: '34',
  vacaAr: '33',
  arbolAr: '32',
  autoAr: '30'
}

export const maturityChartSections = [
  { value: 'M', rangeStart: 0, rangeEnd: 7 },
  { value: 'M+', rangeStart: 8, rangeEnd: 14 },
  { value: 'M++', rangeStart: 15, rangeEnd: 21 },
  { value: 'M+++', rangeStart: 22, rangeEnd: Infinity },
];

export const EVENT_TYPES = {
  TRANSFERENCIA: 1000,
  MASIVO: 0,
  CAMBIO_DE_PESO: 1,
  CAMBIO_DE_SISTEMA_PRODUCTIVO: 3,
  CAMBIO_DE_DENTICION: 5,
  CAMBIO_DE_CATEGORIA: 6,
  MANGA: 7,
  CAMBIO_DE_CONDICION_CORPORAL: 9,
  DAR_DE_BAJA: 13,
  CERTIFICADOS: 8,
  FAENA: 10,
};

export const PATHS_WHITELIST = [
  'signin/users',
  USER_EDIT,
  COMPANY_ALL,
  COMPANY_NEW,
  COMPANY_EDIT,
  COMPANY_REGISTER,
  COMPANY_ACTIVATE,
  BOXES_ALL,
  BOXES_NEW,
  BOXES_EDIT,
  BOX_REGISTER,
  BOX_ACTIVATE,
  USER_DELEGATIONS,
  USER_DELEGATIONS_ALL,
  USER_DELEGATIONS_COMPANY_ALL
]

export const REQUIRED_FIELDS_PATHS = [
  HOME,
  EVENTS,
  TRANSFERS,
  QR_TEMPLATES,
  ACTIVITIES,
  LOTS,
  LOGICAL_DELETED_ASSETS,
];

export const DATE_FORMATS = {
  iso_int_s: 'YYYY-MM-DD',
  iso_int: 'YYYY/MM/DD',
  short_date: 'DD/MM/YYYY',
  short_date_usa: 'MM/DD/YYYY',
  short_date_s: 'DD-MM-YYYY',
  short_date_usa_s: 'MM-DD-YYYY',
  iso_date_time: 'YYYY-MM-DDTHH:MM:SS',
  iso_date_time_utc: 'YYYY-MM-DDTHH:MM:SSZ',
  //define other well known formats if you want
};

export const DEFAULT_LANGUAGES = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Español' },
};

export const FILE_TYPES = ['JPG', 'PNG', 'JPEG', 'TIFF', 'BMP'];

export const STORAGE_USER = 'storage-user-' + config.env;

export const ICON_PROPS = {
  iconUrl: 'https://unpkg.com/leaflet@1.3.4/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.3.4/dist/images/marker-icon.png',
  iconAnchor: [5, 40],
  popupAnchor: [10, -44],
  iconSize: [25, 40],
};

export const ICON = icon(ICON_PROPS);

export const ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

export const ATTRIBUTION_STADIA = '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a> contributors';

export const DEFAULT_CENTER = {
  lat: -34.843095555029784,
  lng: -64.12962470843297,
};
export const DEFAULT_ZOOM = 5;

const rootToolBarTranslate = 'polygonToolbar';

export const TOOLBAR_CONFIG = (t) => {
  const translateToolbar = {
    draw: {
      toolbar: {
        actions: {
          title: t(`${rootToolBarTranslate}.draw.toolbar.actions.cancel.title`),
          text: t(`${rootToolBarTranslate}.draw.toolbar.actions.cancel.text`),
        },
        finish: {
          title: t(`${rootToolBarTranslate}.draw.toolbar.finish.title`),
          text: t(`${rootToolBarTranslate}.draw.toolbar.finish.text`),
        },
        undo: {
          title: t(`${rootToolBarTranslate}.draw.toolbar.undo.title`),
          text: t(`${rootToolBarTranslate}.draw.toolbar.undo.text`),
        },
        buttons: {
          polyline: t(`${rootToolBarTranslate}.draw.toolbar.button.polyline`),
          polygon: t(`${rootToolBarTranslate}.draw.toolbar.button.polygon`),
          rectangle: t(`${rootToolBarTranslate}.draw.toolbar.button.rectangle`),
          circle: t(`${rootToolBarTranslate}.draw.toolbar.button.circle`),
          marker: t(`${rootToolBarTranslate}.draw.toolbar.button.marker`),
          circlemarker: t(
            `${rootToolBarTranslate}.draw.toolbar.button.circleMarker`
          ),
        },
      },
      handlers: {
        circle: {
          tooltip: {
            start: t(
              `${rootToolBarTranslate}.draw.handler.circle.tooltip.start`
            ),
          },
          radius: t(`${rootToolBarTranslate}.draw.handler.circle.radius`),
        },
        circlemarker: {
          tooltip: {
            start: t(
              `${rootToolBarTranslate}.draw.handler.circlemarker.tooltip.start`
            ),
          },
        },
        marker: {
          tooltip: {
            start: t(
              `${rootToolBarTranslate}.draw.handler.marker.tooltip.start`
            ),
          },
        },
        polygon: {
          tooltip: {
            start: t(
              `${rootToolBarTranslate}.draw.handler.polygon.tooltip.start`
            ),
            cont: t(
              `${rootToolBarTranslate}.draw.handler.polygon.tooltip.cont`
            ),
            end: t(`${rootToolBarTranslate}.draw.handler.polygon.tooltip.end`),
          },
        },
        polyline: {
          error: t(`${rootToolBarTranslate}.draw.handler.polyline.error`),
          tooltip: {
            start: t(
              `${rootToolBarTranslate}.draw.handler.polyline.tooltip.start`
            ),
            cont: t(
              `${rootToolBarTranslate}.draw.handler.polyline.tooltip.cont`
            ),
            end: t(`${rootToolBarTranslate}.draw.handler.polyline.tooltip.end`),
          },
        },
        rectangle: {
          tooltip: {
            start: t(
              `${rootToolBarTranslate}.draw.handler.rectangle.tooltip.start`
            ),
          },
        },
        simpleshape: {
          tooltip: {
            end: t(
              `${rootToolBarTranslate}.draw.handler.simpleshape.tooltip.end`
            ),
          },
        },
      },
    },
    edit: {
      toolbar: {
        actions: {
          save: {
            title: t(`${rootToolBarTranslate}.edit.toolbar.actions.save.title`),
            text: t(`${rootToolBarTranslate}.edit.toolbar.actions.save.text`),
          },
          cancel: {
            title: t(
              `${rootToolBarTranslate}.edit.toolbar.actions.cancel.title`
            ),
            text: t(`${rootToolBarTranslate}.edit.toolbar.actions.cancel.text`),
          },
          clearAll: {
            title: t(
              `${rootToolBarTranslate}.edit.toolbar.actions.clearAll.title`
            ),
            text: t(
              `${rootToolBarTranslate}.edit.toolbar.actions.clearAll.text`
            ),
          },
        },
        buttons: {
          edit: t(`${rootToolBarTranslate}.edit.toolbar.buttons.edit`),
          editDisabled: t(
            `${rootToolBarTranslate}.edit.toolbar.buttons.editDisabled`
          ),
          remove: t(`${rootToolBarTranslate}.edit.toolbar.buttons.remove`),
          removeDisabled: t(
            `${rootToolBarTranslate}.edit.toolbar.buttons.removeDisabled`
          ),
        },
      },
      handlers: {
        edit: {
          tooltip: {
            text: t(
              `${rootToolBarTranslate}.edit.toolbar.handlers.edit.tooltip.text`
            ),
            subtext: t(
              `${rootToolBarTranslate}.edit.toolbar.handlers.edit.tooltip.subtext`
            ),
          },
        },
        remove: {
          tooltip: {
            text: t(
              `${rootToolBarTranslate}.edit.toolbar.handlers.remove.tooltip.text`
            ),
          },
        },
      },
    },
  };
  return translateToolbar;
};

export const DEFAULT_POINT = {
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [DEFAULT_CENTER.lng, DEFAULT_CENTER.lat],
  },
  properties: {
    center: { lat: DEFAULT_CENTER.lat, lng: DEFAULT_CENTER.lng },
  },
};

export const CREATEEDITCONTROLDRAW = (featureGroup) => ({
  edit: {
    featureGroup: featureGroup,
    remove: true,
  },
  polyline: false,
  marker: false,
  circle: false,
  circlemarker: false,
  rectangle: false,
});

export const MAX_STRING_LENGTH = 256;

export const pollingConfig = {
  interval: 60000,
  timeout: 15000
}

export const ASSET_STATES = {
  FAENADO: 'Faenado',
  DADO_DE_BAJA: 'Dado de Baja',
  CUARENTENA: 'Cuarentena',
  PRODUCTIVO: 'Productivo'
};

export const DISPOSAL_TYPES = {
  FAENA: 'FAENA',
  DESAPARICION: 'DESAPARICION',
  VENTA: 'VENTA',
  MUERTE: 'MUERTE'
};

export const assetTypeDict = { 35: "a3" };

export const supplyChainDict = {
  CERDOS: "3",
  VEHICULOS: "5",
  CEREALES: "9",
  ARBOLES: "10",
  VACAS: "11",
  MTR: "12",
  BELGOMALT: "13", 
};

export const relevantSupplyChains = [supplyChainDict.MTR];

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Typography, TextField, FormControlLabel, Checkbox, Button, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import BaseBasicForm from '../../../Layout/containers/BaseBasicForm';
import { ApiFetch } from '../../../Utils/ApiFetch';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import leftIcon from '../../../Images/stepbystep/lefticons/bienvenido.png';
import { LOGIN } from '../../../constants/paths'
import { useTranslation } from 'react-i18next';
import { getInvitationByHash } from '../../../services/invitation';
import { signUp } from '../../../services/user';
import LanguageSelect from '../../../Components/LanguageSelect';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SignUpUser() {
  const { t } = useTranslation();
  let history = useHistory();
  const globalContext = useGlobalContext();
  const query = useQuery();
  const invitationHash = query.get("invitation");

  const [checkTerms, setCheckTerms] = useState(false);
  const [showErrorUser, setShowErrorUser] = useState(null);
  const [msgErrorUser, setMsgErrorUser] = useState('');
  const [equalPass, setEqualPass] = useState(false);
  const [showErrorPass, setShowErrorPass] = useState(null);
  const [msgErrorPass, setMsgErrorPass] = useState('');
  const [showErrorNames, setShowErrorNames] = useState(null);
  const [msgErrorNames, setMsgErrorNames] = useState('');
  const [showErrorAPI, setShowErrorAPI] = useState(null);
  const [msgErrorAPI, setMsgErrorAPI] = useState('');
  const [email, setEmail] = useState('');
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [lastName, setLastName] = useState('');
  const [country] = useState('');
  const [hashInvalid, setHashInvalid] = useState(false);

  useEffect(() => {
    if (invitationHash) {
      const fetchInvitation = async () => {
        try {
          const invitationData = await getInvitationByHash(invitationHash);
          setEmail(invitationData || '');
          setHashInvalid(false);
        } catch (error) {
          setHashInvalid(true);
          setMsgErrorAPI("Hash de invitación no válido. Por favor, verifica el enlace.");
        }
      };

      fetchInvitation();
    }
  }, [invitationHash]);

  useEffect(() => {
    if (email) {
      handleInputOnBlur('email');
    }
  }, [email]);

  const handleInputOnBlur = (input) => {
    switch (input) {
      case 'email':
        if (!email) {
          setShowErrorUser(true)
          setMsgErrorUser('emailRequired')
          return
        } else {
          setShowErrorUser(false)
          const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
          if (!emailRegex.test(email)) {
            setShowErrorUser(true)
            setMsgErrorUser('wrongEmailFormat')
            return
          }
        }
        break;

      case 'pass1':
        if (pass1.length < 6 || pass1.length > 50) {
          setShowErrorPass(true)
          setMsgErrorPass('invalidLengthPass')
          return
        } else setShowErrorPass(false)
        if (!isAlfaNumeric(pass1)) {
          setShowErrorPass(true)
          setMsgErrorPass('invalidAlfanumericPasswords')
          return
        } else setShowErrorPass(false)
        if (!equalPass && pass2) {
          setShowErrorPass(true)
          setMsgErrorPass('differentPasswords')
          return
        } else setShowErrorPass(false)
        break;

      case 'pass2':
        if (pass2.length < 6 || pass2.length > 50) {
          setShowErrorPass(true)
          setMsgErrorPass('invalidLengthPass')
          return
        } else setShowErrorPass(false)
        if (!isAlfaNumeric(pass2)) {
          setShowErrorPass(true)
          setMsgErrorPass('invalidAlfanumericPasswords')
          return
        } else setShowErrorPass(false)
        if (!equalPass) {
          setShowErrorPass(true)
          setMsgErrorPass('differentPasswords')
          return
        } else setShowErrorPass(false)
        break;

      case 'firstName':
        if (firstName.length <= 1) {
          setShowErrorNames(true)
          setMsgErrorNames('namesLength')
          return
        } else setShowErrorNames(false)
        break;

      case 'lastName':
        if (lastName.length <= 1) {
          setShowErrorNames(true)
          setMsgErrorNames('namesLength')
          return
        } else setShowErrorNames(false)
        break;

      default: return
    }
  }

  const isAlfaNumeric = (password) => {
    const hadLetters = /[A-Za-z]/.test(password);
    const hadNumbers = /\d/.test(password);

    return hadLetters && hadNumbers;
  }

  const handleOnClick = async (event) => {
    if (!checkTerms) return;
  
    let args = {
      hash: invitationHash,
      email: email,
      password: pass1,
      firstname: firstName,
      lastname: lastName,
      l1: country,
      userData: {
        box_context: {
          model: 5,
          parentId: 0,
          parentType: 'company',
          objectTypeSubType: 2,
          parentTypeSubType: 1,
        },
        asset_context: {
          model: 5,
          parentId: 100,
          parentType: 'box',
          objectTypeSubType: 5,
          parentTypeSubType: 1,
        },
      },
    };
  
    setDisabledSubmit(true);
  
    try {
      const response = await signUp(args);
      if (response && response.status === 200) {
        history.push({
          pathname: '/public/signup/verify',
          state: { email: email },
        });
      }
    } catch (error) {
      setShowErrorAPI(true);
      setMsgErrorAPI(error.message || "Error en la validación. Por favor, intenta de nuevo.");
    } finally {
      setDisabledSubmit(false);
    }
  };

  const checkPass1 = (value) => {
    setPass1(value)
    if (value === pass2) setEqualPass(true)
    else setEqualPass(false)
  }

  const checkPass2 = (value) => {
    setPass2(value)
    if (value === pass1) setEqualPass(true)
    else setEqualPass(false)
  }

  const fullItemCheck = () => {
    if (showErrorUser === false && showErrorPass === false && showErrorNames === false) return true
    else return false
  }

  return (
    <BaseBasicForm text={t("signUp.title")} leftIcon={leftIcon} showOriginoImage="true">
      <Box display="flex" justifyContent="flex-end" width="100%" sx={{
        mr: { xl: 2 },
        mt: 2,
      }}>
        <LanguageSelect />
      </Box>
      <FontAwesomeIcon icon={faUserPlus} size="2x" color="#3969E8" />
      <Typography variant="subtitle1" component="h4" color="text.secondary" className="mb-2">
        {t("signUp.subtitle")}
      </Typography>
      <TextField
        id="email"
        label={t("signUp.email")}
        className="mt-2"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onBlur={() => handleInputOnBlur('email')}
        fullWidth
      />
      {(showErrorUser) &&
        <span style={{ textAlign: "center", color: "red" }}>
          <br />{t("signUp.errorMsg." + msgErrorUser)}<br />
        </span>
      }
      <TextField
        id="password"
        label={t("signUp.password1")}
        type="password"
        onChange={(e) => checkPass1(e.target.value)}
        onBlur={() => handleInputOnBlur('pass1')}
        helperText={t("signUp.passwordHelperText")}
        className="mt-2"
        fullWidth
      />
      <TextField
        id="password_2"
        label={t("signUp.password2")}
        type="password"
        onChange={(e) => checkPass2(e.target.value)}
        onBlur={() => handleInputOnBlur('pass2')}
        helperText={t("signUp.passwordHelperText")}
        className="mt-2"
        fullWidth
      />
      {(showErrorPass) &&
        <span style={{ textAlign: "center", color: "red" }}>
          <br />{t("signUp.errorMsg." + msgErrorPass)}<br />
        </span>
      }
      <TextField
        id="firstname"
        label={t("signUp.firstName")}
        className="mt-2"
        onChange={(e) => setFirstName(e.target.value)}
        onBlur={() => handleInputOnBlur('firstName')}
        fullWidth
      />
      <TextField
        id="lastname"
        label={t("signUp.lastName")}
        className="mt-2"
        onChange={(e) => setLastName(e.target.value)}
        onBlur={() => handleInputOnBlur('lastName')}
        fullWidth
      />
      {(showErrorNames) &&
        <span style={{ textAlign: "center", color: "red" }}>
          <br />{t("signUp.errorMsg." + msgErrorNames)}<br />
        </span>
      }
      <div className='flex-row justify-content-center'>
        <FormControlLabel
          control={<Checkbox checked={checkTerms} onChange={() => setCheckTerms(!checkTerms)} />}
          label={t("signUp.termsAndConditions.0")}
          className="flex my-2 mx-0 align-middle"
        />
        <a className="flex my-2 mx-1 align-middle text-decoration-none"
          href="/TermsAndConditions_Origino_ES.html" target="_blank">
          {t("signUp.termsAndConditions.1")}
        </a>
      </div>
      {hashInvalid && (
        <Typography variant="body1" component="p" color="error">
          {msgErrorAPI}
        </Typography>
      )}
      {(showErrorAPI) &&
        <span style={{ textAlign: "center", color: "red" }}>
          {t("signUp.errorMsg." + msgErrorAPI)}<br />
        </span>
      }
      {(msgErrorAPI === 'userExist') &&
        <span style={{ textAlign: "center" }}>
          <a className="text-decoration-none" href={LOGIN}>{t("signUp.errorMsg.loginHere")}</a>
        </span>
      }
      {checkTerms && fullItemCheck() && (
        <Button variant="contained" className="mt-2" disabled={disabledSubmit} onClick={handleOnClick}>
          {t("signUp.createAccount")}
        </Button>
      )}
      {(!checkTerms || !fullItemCheck()) && (
        <Button variant="contained" className="mt-2" disabled>
          {t("signUp.createAccount")}
        </Button>
      )}
    </BaseBasicForm>
  );
}

export default SignUpUser;

import { ACTIVE_MODEL, ASSET_STATES, DISPOSAL_TYPES } from "../constants";

export const getAttributeValue = (attributes, name) => {
  if (Array.isArray(attributes)) {
    const attribute = attributes.find(attr => attr.name === name);
    return attribute?.data?.value || null;
  } else if (typeof attributes === 'object' && attributes !== null) {
    return attributes[name] || null;
  } else {
    console.warn("attributes no es un arreglo ni un objeto:", attributes);
    return null;
  }
};

export const vacaPnState = (attributes, showReason = true) => {
  const disposalType = getAttributeValue(attributes, "disposal_type")
  ?.toUpperCase()
  const quarantineStartDate = getAttributeValue(attributes, "quarantine_start_date");
  const quarantineEndDate = getAttributeValue(attributes, "quarantine_end_date");
  const today = new Date();

  if (disposalType === DISPOSAL_TYPES.FAENA) return ASSET_STATES.FAENADO;
  if (disposalType) {
    return showReason
      ? `${ASSET_STATES.DADO_DE_BAJA} - Motivo: ${disposalType}`
      : disposalType;
  }

  if (quarantineStartDate && quarantineEndDate) {
    const startDate = new Date(quarantineStartDate);
    const endDate = new Date(quarantineEndDate);
    return today >= startDate && today <= endDate ? ASSET_STATES.CUARENTENA : ASSET_STATES.PRODUCTIVO;
  }

  if (quarantineStartDate && !quarantineEndDate) return ASSET_STATES.CUARENTENA;

  return ASSET_STATES.PRODUCTIVO;
};

export const vacaArState = (attributes, showReason = true) => {
  const disposalType = getAttributeValue(attributes, "disposal_type")
  ?.toUpperCase()

  if (disposalType === DISPOSAL_TYPES.FAENA) return ASSET_STATES.FAENADO;

  if (disposalType) {
    return showReason
      ? `${ASSET_STATES.DADO_DE_BAJA} - Motivo: ${disposalType}`
      : disposalType;
  }

  return ASSET_STATES.PRODUCTIVO;
};

export const stateRules = {
  [ACTIVE_MODEL.vacaPn]: vacaPnState,
  [ACTIVE_MODEL.vacaAr]: vacaArState,
};
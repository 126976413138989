import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import originoLogoFull from "../../Images/origino_logo_full.png";
import { StyledContainer, StyledLeftSide, StyledRightSide } from "../../pages/SignIn/SignIn.styles";
import { ThemeContext } from '../../Contexts/themeContext';

const BaseBasicForm = (props) => {
  const { logoTenant } = useContext(ThemeContext);

  return (
    <StyledContainer container spacing={0}>
      <StyledLeftSide
        style={{
          backgroundImage: `url(${originoLogoFull})`,
          backgroundPosition: 'top',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '100vh',
        }}
        item xs={6}
        className="d-flex flex-column align-center justify-center"
      >
        <Box
          style={{
            marginTop:'15px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {logoTenant && (
            <img
              style={{
                width: '75px',
                marginTop: '35px'
              }}
              src={logoTenant}
              alt="Tenant Logo"
            />
          )}
          <Typography variant="h4"
            style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              alignContent: 'center', 
              justifyContent: 'space-evenly',
            }}
            component="h2"
            color="text.blue">
            {(props.leftIcon) &&
              <>
                <img
                  alt="leftIcon"
                  className="mb-2"
                  style={{
                    width: "70px"
                  }} src={props.leftIcon}
                />
              </>
            }
            {props.text}
          </Typography>

          {props.showOriginoImage && false && <img src={originoLogoFull} alt="Origino" />}
        </Box>
      </StyledLeftSide>
      <StyledRightSide item xs={6} className="d-flex flex-column align-center justify-center">
        {props.children}
      </StyledRightSide>
    </StyledContainer>
  );
};

export default BaseBasicForm;

import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

export const DataGridOracles = (props) => {
  const { title, rows, columns, type, checkboxSelect, onSelection, selectionModel, loading } = props

  const { t } = useTranslation();
  const [pageSize] = useState(20);
  const defaultPagination = { field: 'Id', sort: 'desc' }
  const [sortModel, setSortModel] = useState([defaultPagination]);
  const [rowsState, setRowsState] = useState({ page: 0, pageSize: pageSize });  

  return (
    <>
      {title && 
        <Box 
          style={{ 
            paddingLeft: '10px', 
            color: '#6d6d6d', 
            fontSize: '0.9rem', 
            marginBottom: 10
          }}
        >
          {title}
        </Box>
      }
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={rows.length}
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) => setRowsState((prev) => ({ ...prev, pageSize }))}
        sortModel={sortModel}
        onSortModelChange={(sortModel) => setSortModel(sortModel)}
        disableRowSelectionOnClick
        paginationMode="client"
        sortingMode="client"
        rowsPerPageOptions={[20, 30, 50]}
        componentsProps={{
          pagination: {
            labelRowsPerPage: t("largeGrid.pagination"),
          }
        }}
        sx= {{
          height: '475px',
          '& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel, & .MuiInputBase-input': {
            margin: 0,
            py: 1,
          }
        }}
        isRowSelectable={type === 'assets' && ((params) => 
          params.row.asset_status_change === 'Activo' || 
          params.row.asset_status_change === 'Validado' ||
          params.row.asset_status_change === 'Observado'
        )}
        checkboxSelection={checkboxSelect}
        onSelectionModelChange={(ids) => onSelection && onSelection(ids)}
        selectionModel={selectionModel}
        {...rowsState}
        loading={loading}
      />
    </>
  )
}

import React, { useEffect } from 'react';
import { Grid, Dialog, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorOutline } from '@mui/icons-material';


function ModalErrorInfo({ open, toggleModal, modalText }) {
  useEffect(() => {
  }, [toggleModal]);

  return (
    <Dialog onClose={() => toggleModal(false)} open={open} >
      <IconButton
        aria-label="close"
        onClick={() => toggleModal(false)}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '8px',
        }}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className="text-center mt-4 mb-4">
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <ErrorOutline sx={{ color: "#e91e63" }} fontSize='large' />
          </Grid>
          <Grid item xs={12} dangerouslySetInnerHTML={{ __html: modalText }} sx={{ maxHeight: '350px', overflowY: 'auto' }}/>
        </Grid>
      </DialogTitle>
    </Dialog>
  );
}

export default ModalErrorInfo;

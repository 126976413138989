import { Grid } from '@material-ui/core';
import { Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { ApiFetch } from '../../Utils/ApiFetch';
import { useHistory } from 'react-router-dom';
import { FiltersMetrics } from './FiltersMetrics';
  
function QRsMetrics () {
    const columns = [
        {
            field: 'actions',
            headerName: 'Actions',
            width: 125,
            renderCell: (params) => (
              <Button
                variant="contained"
                color="primary"
                size='small'
                sx={{
                    height:25,
                    fontSize: 12
                }}
                disabled={params.row.clicks === "0"}
                onClick={() => handleOnViewLogs(params.row)}
              >
                {t('qrMetrics.viewLogs')}
              </Button>
            ),
          },
        { 
            field: 'id', 
            headerName: 'ID', 
            width: 100,
            headerAlign: 'center', 
            align: 'center',
        },
        { 
            field: 'qrtype',
            headerName: 'QR Type',
            width: 150,
            editable: true,
            valueGetter: (params) => {
                return params.value
                    ? params.value.replace(/^qr/, '').replace(/([A-Z])/g, ' $1').trim()
                    : '';
            },
        },
        {
            field: 'clicks',
            headerName: 'Clicks',
            width: 150,
            editable: true,
        },
    ];
    
    const { t } = useTranslation();
    const history = useHistory();
    
    const { userData } = useGlobalContext();
    const globalContext = useGlobalContext();
    const { topBar } = { ...userData?.userData?.userPreferences }


    const [selectedEstablishment, setSelectedEstablishment] = useState(topBar?.establishment?.id ?? '');
    const [selectedQrType, setSelectedQrType] = useState('qrIdenteco');

    const [rows, setRows] = useState([])
    const [rowCount, setRowCount] = useState(undefined);

    const [pageSize] = useState(20);
    const defaultPagination = { field: 'id', sort: 'desc' }
    const [rowsState, setRowsState] = useState({ page: 0, pageSize: pageSize });
    const [sortModel, setSortModel] = useState([defaultPagination]);
    
    const handleOnViewLogs = (row) => {
        const { id, qrtype } = row;
        history.push({
            pathname: `/qrLogs/${qrtype}/${id}`,
        })
    }


    const getQRsMetrics = async () => {
        if(selectedQrType && selectedEstablishment)
        await ApiFetch(
            `qrLogs/${selectedQrType}/${selectedEstablishment}/${rowsState?.pageSize}/${rowsState?.page}/${sortModel[0]?.field ?? defaultPagination.field}/${sortModel[0]?.sort  ?? defaultPagination.sort}`,
            globalContext,
            true,
            async (body) => {
              let resJson = await body.json();
              resJson.res?.length > 0 && setRows(resJson.res)
              resJson.resTotal && setRowCount(resJson.resTotal )
            },
            (error) => {
              console.log('error getQRsMetrics', error);
            }
        );    
    }

    useEffect(()=> {
        getQRsMetrics()
    }, [rowsState, sortModel])

    return (
        <Grid>
            <FiltersMetrics 
                setSelectedEstablishment={setSelectedEstablishment}
                setSelectedQrType={setSelectedQrType}
                getQRsMetrics={getQRsMetrics}
                selectedEstablishment={selectedEstablishment}
                selectedQrType={selectedQrType}
                topBar={topBar}
            />
            <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={rowCount}
                    onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
                    onPageSizeChange={(pageSize) => setRowsState((prev) => ({ ...prev, pageSize }))}
                    onSortModelChange={(sortModel) =>  setSortModel(sortModel)}
                    disableRowSelectionOnClick
                    paginationMode="server"
                    sortingMode="server"
                    rowsPerPageOptions={[20, 30, 50]}
                    sortModel={sortModel}
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: t("largeGrid.pagination"),
                        }
                    }}
                    sx= {{
                        '& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel, & .MuiInputBase-input': {
                          margin: 0,
                          py: 1,
                        }
                    }}
                    {...rowsState}
                />
            </Box>
        </Grid>
    )
}

export default QRsMetrics;

import * as L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-geosearch/dist/geosearch.css';
import './BasicDrawer.css';
import 'leaflet/dist/leaflet.css';

import { useEffect, useState } from 'react';
import {
  FeatureGroup,
  MapContainer,
  TileLayer,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import { OpenStreetMapProvider } from 'react-leaflet-geosearch';
import SearchControl from './SearchControl';
import { useLeafletContext } from '@react-leaflet/core';

import {
  ATTRIBUTION,
  ATTRIBUTION_STADIA,
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  TOOLBAR_CONFIG,
  CREATEEDITCONTROLDRAW,
} from '../../constants';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress } from '@mui/material';

const FigureMarker = ({ geoJson }) => {
  const context = useLeafletContext();
  const figureType = geoJson?.geometry?.type;
  const radius = geoJson?.properties?.radius ?? 1;
  const coordinates =
    getCordinates(figureType, geoJson?.geometry?.coordinates) ?? [];

    const FIGURES = {
    Polygon: ({ coordinates }) =>
      new L.Polygon(coordinates, { color: 'yellow' }),
    Point: ({ coordinates, radius }) => L.Circle(coordinates, radius),
    Rectangle: ({ coordinates }) => new L.Rectangle(coordinates),
    Polyline: ({ coordinates }) => new L.Polyline(coordinates),
  };

  function getCordinates(type, coordinates) {
    if (type === 'Polygon') {
      return coordinates?.map((firstArray) =>
        firstArray.map(([lat, long]) => [long, lat])
      );
    } else {
      return coordinates?.length > 0 ? [coordinates[1], coordinates[0]] : [];
    }
  }
  
  useEffect(() => {
    const currentFigure = FIGURES[figureType];
    if (currentFigure != null) {
      const current = currentFigure({ coordinates, radius });
      const container = context.layerContainer || context.map;
      
      container?.eachLayer((layer) => {
        if (layer instanceof L.Polygon || layer instanceof L.Circle) {
          container.removeLayer(layer);
        }
      });
  
      geoJson && container.addLayer(current);
    }
  }, [geoJson, context]);
  
  return null;
};

function BasicDrawer({ geoJson, name, boxLocation, modeSatelite, updateLocation }) {
  const [featuresLayer, setFeaturesLayer] = useState(null);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [drawOptions, setDrawOptions] = useState(null);
  const [map, setmap] = useState(null);

  const [center, setCenter] = useState(
    (boxLocation && {lat: boxLocation.geometry.coordinates[1], lng: boxLocation.geometry.coordinates[0]}) ??
    geoJson?.properties?.center ?? DEFAULT_CENTER
  );
  const [zoom, setZoom] = useState(boxLocation ? 14 : geoJson?.properties?.zoom ?? DEFAULT_ZOOM);

  const { t } = useTranslation();

  const localeToolBarConfig = TOOLBAR_CONFIG(t);

  L.drawLocal = localeToolBarConfig;

  useEffect(() => {
    if (geoJson?.properties?.center) {
      if (map) {
        map.flyTo(geoJson.properties.center);
        const coordinates = geoJson.geometry.coordinates[0];
  
        let minLat = 9999999;
        let minLong = 9999999;
        let maxLat = -9999999;
        let maxLong = -9999999;
  
        for (let point of coordinates) {
          minLat = point[1] < minLat ? point[1] : minLat;
          minLong = point[0] < minLong ? point[0] : minLong;
          maxLat = point[1] > maxLat ? point[1] : maxLat;
          maxLong = point[0] > maxLong ? point[0] : maxLong;
        }
  
        map.fitBounds([
          [minLat, minLong],
          [maxLat, maxLong],
        ]);
  
        const latLngs = coordinates.map(([lng, lat]) => [lat, lng]);
        const polygon = L.polygon(latLngs, { color: 'blue' });
  
        if (featuresLayer.current) {
          featuresLayer.current.clearLayers();
          featuresLayer.current.addLayer(polygon);
        } 
      }
  
      setCenter(geoJson.properties.center);
    }
  }, [featuresLayer, geoJson, map]);

  useEffect(() => {
    if (map && featuresLayer) {
      const editOptions = CREATEEDITCONTROLDRAW(featuresLayer);
  
      setDrawOptions(editOptions);
    }
  }, [map, featuresLayer]);

  function handleCreate({ layer, target }) {
    const layerGeojson = layer.toGeoJSON();
    const locatedMap = target.locate();
    layerGeojson.properties.zoom = locatedMap._zoom;
    layerGeojson.properties.center = locatedMap._lastCenter;

    setZoom(layerGeojson.properties.zoom);
    setCenter(layerGeojson.properties.center);

    if (layer instanceof L.Circle) {
      layerGeojson.properties.radius = layer.getRadius();
    }

    updateLocation(layerGeojson);
  }

  function handleEdit({ layers, target }) {
    layers.eachLayer((layer) => {
      const layerGeojson = layer.toGeoJSON();
      if (layer instanceof L.Circle) {
        layerGeojson.properties.radius = layer.getRadius();
      }
      const locatedMap = target.locate();
      layerGeojson.properties.zoom = locatedMap._zoom;
      layerGeojson.properties.center = locatedMap._lastCenter;
      updateLocation(layerGeojson);
    });
  }

  function handleDelete(e) {
    updateLocation(null);
  }
  

  function downloadKMZ() {
    setLoadingDownload(true);
    if (!geoJson) return;

    const coordinates = geoJson.geometry.coordinates[0]
        .map(coord => `${coord[0]},${coord[1]},0`)
        .join(' ');

    const kmlContent = `
      <?xml version="1.0" encoding="UTF-8"?>
      <kml xmlns="http://www.opengis.net/kml/2.2">
        <Document>
          <name>${name || 'Polígono'}</name>
          <Placemark>
            <name>${name || 'Polígono'}</name>
            <description>Polígono generado desde la app</description>
            <Polygon>
              <outerBoundaryIs>
                <LinearRing>
                  <coordinates>
                    ${coordinates}
                  </coordinates>
                </LinearRing>
              </outerBoundaryIs>
            </Polygon>
          </Placemark>
        </Document>
      </kml>
    `;

    const blob = new Blob([kmlContent.trim()], { type: 'application/vnd.google-earth.kml+xml' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${name || 'Polígono'}.kml`;
    a.click();
    URL.revokeObjectURL(url);
    setLoadingDownload(false);
  }

  async function handleUpload(event) {
    setLoadingUpdate(true);
    const file = event.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
  
    reader.onload = async (e) => {
      let content = e.target.result;

      content = content.trimStart(); 
      if (!content.startsWith('<?xml')) {
        console.log('El archivo no tiene una declaración XML válida.');
        return;
      }

      const parser = new DOMParser();
      const kml = parser.parseFromString(content, 'application/xml');
      const polygons = kml.getElementsByTagName('Polygon');

      if (polygons.length === 0) {console.log('Error en carga de Polígono: No se ha encontrado el polígono en el archivo.'); return;}

      const coordinates = polygons[0].getElementsByTagName('coordinates')[0]?.textContent.trim();

      if (!coordinates) {console.log('Error en carga de Polígono: No se ha encontrado las coordenadas en el archivo.'); return;}
    
      const latLngs = coordinates.split(' ').map(coord => {
        const [lng, lat] = coord.split(',').map(Number);
        return [lat, lng];
      }).filter(coord => coord.length === 2);
    
      if (latLngs.length === 0) {
        console.log('Error en carga de Polígono: No se ha encontrado la Latitud y Longitud en el archivo.');
        return;
      }
    
      //Validación para cerrar el polígono, si es necesario.
      if (latLngs[0][0] !== latLngs[latLngs.length - 1][0] ||
          latLngs[0][1] !== latLngs[latLngs.length - 1][1]) {
        latLngs.push(latLngs[0]);
      }
    
      const geoJsonData = {
        type: "Feature",
        properties: {
          zoom: map.getZoom(),
          center: [map.getCenter().lat, map.getCenter().lng],
        },
        geometry: {
          type: "Polygon",
          coordinates: [latLngs.map(([lat, lng]) => [lng, lat])],
        },
      };
    
      updateLocation(geoJsonData);
    };
    
  
    reader.readAsText(file);
    setLoadingUpdate(false);
  }

  const prov = OpenStreetMapProvider();

  return (
    <>
      <MapContainer
        style={{ height: '50vh', width: '15wh' }}
        zoom={zoom}
        center={center}
        whenCreated={(mapInstance) => {
          setmap(mapInstance);
          setFeaturesLayer(L.featureGroup().addTo(mapInstance));
        }}
      >
        <SearchControl
          provider={prov}
          showMarker={false}
          showPopup={true}
          popupFormat={({ query, result }) => result.label}
          maxMarkers={3}
          retainZoomLevel={false}
          animateZoom={true}
          autoClose={false}
          searchLabel={'Ingresá la ciudad más cercana'}
          keepResult={true}
        />

        <TileLayer
          attribution={ATTRIBUTION}
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />

        <TileLayer
          attribution={ATTRIBUTION_STADIA}
          url="https://tiles.stadiamaps.com/tiles/stamen_toner_labels/{z}/{x}/{y}{r}.{ext}"
          subdomains={'abcd'}
          minZoom={0}
          maxZoom={20}
          ext={'png'}
        />

        {!modeSatelite &&
          <TileLayer
            attribution={ATTRIBUTION_STADIA}
            url="https://tiles.stadiamaps.com/tiles/stamen_terrain_background/{z}/{x}/{y}{r}.{ext}"
            subdomains={'abcd'}
            minZoom={0}
            maxZoom={20}
            ext={'png'}
          />
        }

        <FeatureGroup id="features">
          <EditControl
            position="topright"
            onCreated={handleCreate}
            onEdited={handleEdit}
            onDeleted={handleDelete}
            draw={drawOptions}
          />
          {geoJson && <FigureMarker geoJson={geoJson}/>}
        </FeatureGroup>
      </MapContainer>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center', 
          width: '100%',
          marginTop: 10,
        }}
      >
        <input
          type="file"
          accept=".kml"
          onChange={handleUpload}
          style={{ display: 'none' }}
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <Button
            component="span"
            variant="contained"
            sx={{ ml: 1, marginRight: 1 }}
            disabled={loadingUpdate}
          >
            {loadingUpdate && <CircularProgress sx={{marginRight:1}} size={25} color="secondary" />} 
              {t('component.polygon.kml.subir')}
          </Button>
        </label>
        <Button
          type="button"
          disabled={!geoJson || loadingDownload}
          onClick={downloadKMZ}
          sx={{ mr: 1 }}
          variant="contained"
        >
          {loadingDownload && <CircularProgress sx={{marginRight:1}} size={25} color="secondary" />} 
            {t('component.polygon.kml.descargar')}
        </Button>
      </Box>
    </>
  );
}

export default BasicDrawer;

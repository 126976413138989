import React, { useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import useThemeContext from '../../../Hooks/useThemeContext';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SyncInitModelUser } from '../../../Utils/Model';

import {
  StyledButton,
} from './SidebarTop.styles';

import {
  Box,
} from '@mui/material';

import {
  ASSETS,
  COMPANY_ALL,
} from '../../../constants/paths';
import Loading from '../../../Components/Generic/Loading';
import { Controller, useForm } from 'react-hook-form';
import { getTopBarItems } from '../../../services/sideBar';
import { ApiFetch } from '../../../Utils/ApiFetch';
import { assetTypeDict, relevantSupplyChains } from '../../../constants';

function SidebarTop(_props) {
  const { t } = useTranslation();
  let history = useHistory();
  const host = window.location.hostname;
  const subdomain = host.split(".")[0];

  const {
    selectedBox,
    selectedAsset,
    selectedChain,
    saveTopBar,
    updateSessionData,
    addInitiatedItem,
    userData,
    setMandatoryFieldsErrorStack,
    refreshUserData,
  } = useGlobalContext();
  const globalContext = useGlobalContext();

  const { topBar } = { ...userData?.userData?.userPreferences }
  const { control, getValues, resetField, reset } = useForm()

  const { swithMenuMode, menuMode, setActiveThemeFromAsset, setThemeFromEstablishment } =
    useThemeContext();

  const [wait, setWait] = useState(false);

  const [allChains, setAllChains] = useState([]);
  const [allActives, setAllActives] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allEstablishments, setAllEstablishments] = useState([]);

  useEffect(() => {
    if (menuMode !== 'HOME') return;

    setWait(true);

    getTopBarItems()
      .then((res) => {
        const assetTypeMatch = Object.values(assetTypeDict).includes(subdomain);

        if (assetTypeMatch) {
          // Subdominio ES `a3`
          const filteredChains = res.data.suplyChain.filter(
            (chain) => relevantSupplyChains.includes(chain.id)
          );

          if (selectedChain.id && !relevantSupplyChains.includes(selectedChain.id) && selectedChain.id !== -1) {
            // Si la cadena seleccionada no es relevante, resetea
            setAllChains(filteredChains);
            saveTopBar({ chain: {}, active: {}, company: {}, establishment: {} });
            reset({ chain: {}, active: {}, company: {}, establishment: {} });
          } else {
            // Si la cadena seleccionada es relevante o está vacía
            setAllChains(filteredChains);
          }
        } else {
          // Subdominio NO es `a3`, funcionamiento normal
          setAllChains(res.data.suplyChain);
        }

        setAllActives(res.data.actives);
        setAllCompanies(res.data.companies);
        setAllEstablishments(res.data.establishments);
      })
      .catch((err) => console.log('error getTopBarItems', err))
      .finally(() => setWait(false));
  }, [menuMode, selectedChain.id]);

  const validateFields = async () => {
    await ApiFetch(
      'fields/validate/' + selectedBox.id,
      globalContext,
      true,
      async (body) => {
        let resJson = await body.json();
        if (selectedBox.id !== '-1') {
          SyncInitModelUser(selectedBox.id).then(res => {
            updateSessionData({ models: res.data });
            addInitiatedItem('models');
          }).catch(err => console.log('error SyncInitModelUser', err))
        }
        setMandatoryFieldsErrorStack(resJson.error_stack);
        await refreshUserData();
      },
      (error) => {
        console.log('error validateFields', error);
      }
    );
  };

  useEffect(() => {
      validateFields();
  }, [selectedBox.id]);

  useEffect(() => {
    setActiveThemeFromAsset(selectedAsset.id);
    setThemeFromEstablishment(selectedAsset.id)
  }, [selectedAsset]);

  const handleChangeMode = () => {
    if (menuMode === 'PREHOME') {
      swithMenuMode('HOME');
      history.push(ASSETS);
      setActiveThemeFromAsset(selectedAsset.id);
      } else {
      swithMenuMode('PREHOME');
      history.push(COMPANY_ALL);
    }
    window.location.reload(true);
  };

  const HandleOnClickChain = (_chainId) => {
    resetField('active', { defaultValue: '' })
    resetField('company', { defaultValue: '' })
    resetField('establishment', { defaultValue: '' })
  };

  const HandleOnClickActives = (_activeId) => {
    resetField('company', { defaultValue: '' })
    resetField('establishment', { defaultValue: '' })
  };

  const HandleOnClickCompanies = (_companyId) => {
    resetField('establishment', { defaultValue: '' })
  };

  const HandleOnClickEstablishment = async (establishmentId) => {
    const data = getValues(['chain', 'active', 'company', 'establishment'])
    const establishmentSubtype = allEstablishments.find(establishment => establishment.id === establishmentId).subtype
    const establishmentAssetType = allEstablishments.find(establishment => establishment.id === establishmentId).asset_type

    await setThemeFromEstablishment(establishmentAssetType);
  
    ApiFetch(
      `users/relationEntity/${data[2]}/${data[3]}`,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let relation = await res.json();

          await saveTopBar(
            {
              chain: { id: data[0] },
              active: { id: data[1] },
              company: { id: data[2] },
              establishment: { id: data[3], subtype: establishmentSubtype },
              relationEntityUser: relation[0]?.origin
            }
          )
        }
      },
      (error) => {
        console.log(`error users/relationEntity/${data[2]}/${data[3]}`, error);
      },
      {
        method: 'GET',
      }
    );


    await refreshUserData()

    history.push(ASSETS)
  };

  return (
    <>
      {wait
        ? <Loading height={228} />
        : <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: menuMode === 'HOME' ? '242px' : '60px',
              padding: '15px',
              borderRadius: '15px',
              boxShadow: '0px 4px 35px 0px rgba(109, 102, 119, 0.20)'
            }}>
            <StyledButton
              variant="contained"
              onClick={() => {
                handleChangeMode();
              }}>
              {menuMode === 'HOME' ? t('menu.admin') : t('menu.trading')}
            </StyledButton>
            {menuMode === 'HOME' &&
              <>
                <Controller
                  name="chain"
                  control={control}
                  defaultValue={topBar?.chain.id ?? ""}
                  render={({ field: { value, onChange, name } }) => (
                    <TextField
                      label={t('topBar.chain')}
                      size='small'
                      name={name}
                      select
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        HandleOnClickChain(e.target.value)
                      }}
                    >
                      {
                        allChains.map((itemChain) =>
                          <MenuItem value={itemChain.id} key={itemChain.id}>{itemChain.value}</MenuItem>)
                      }
                    </TextField>
                  )}
                />

                <Controller
                  name="active"
                  control={control}
                  defaultValue={topBar?.active.id ?? ''}
                  render={({ field: { value, onChange, name } }) => (
                    <TextField
                      size='small'
                      name={name}
                      select
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        HandleOnClickActives(e.target.value)
                      }}
                      label={t('topBar.active')}
                    >
                      {
                        allActives.filter(active => active.fk === getValues('chain')).map((itemChain) =>
                          <MenuItem value={itemChain.id} key={itemChain.id}>{itemChain.value}</MenuItem>)
                      }
                    </TextField>
                  )
                  }
                />

                <Controller
                  name="company"
                  control={control}
                  defaultValue={topBar?.company.id ?? ''}
                  render={({ field: { value, onChange, name } }) => (
                    <TextField
                      size='small'
                      name={name}
                      select
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        HandleOnClickCompanies(e.target.value)
                      }}
                      label={t('topBar.company')}
                    >
                      {
                        allCompanies
                          .filter(company => company.fk === getValues('active'))
                          .map((itemChain) =>
                            <MenuItem value={itemChain.id} key={itemChain.id}>{itemChain.value}</MenuItem>)
                      }
                    </TextField>
                  )
                  }
                />

                <Controller
                  name="establishment"
                  control={control}
                  defaultValue={topBar?.establishment.id ?? ''}
                  render={({ field: { value, onChange, name } }) => (
                    <TextField
                      size='small'
                      name={name}
                      select
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        HandleOnClickEstablishment(e.target.value)
                      }}
                      label={t('topBar.establishment')}
                    >
                      {
                        allEstablishments
                          .filter(establishment => establishment.fk === getValues('company') && establishment.asset_type === getValues('active'))
                          .map((itemChain) =>
                            <MenuItem value={itemChain.id} key={itemChain.id}>{itemChain.value}</MenuItem>)
                      }
                    </TextField>
                  )
                  }
                />
              </>
            }
          </Box>
        </>
      }
    </>
  );
}

export default SidebarTop;

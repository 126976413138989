import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import {Button} from '@material-ui/core'; //importing material ui component
import {TextField,Select, MenuItem} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Multiselect from 'multiselect-react-dropdown';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ColorPicker from 'material-ui-color-picker'
import BasicfieldCollection from './basicFieldCollection';
import useGlobalContext from '../../Hooks/useGlobalContext';


function BasicForm(props) {

    const { userToken } = useGlobalContext();

    const useStyles = makeStyles((theme) => ({
        button: {
          display: 'block',
          marginTop: theme.spacing(2),
        },
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
        root: {
            flexGrow: 1,
          },
          paper: {
            height: 140,
            width: 100,
          },
          control: {
            padding: theme.spacing(2),
          },
      }));

    const classes = useStyles();

    const [spacing] = React.useState(2);
    const [permissionsDisabled,setPermissionsDisabled] = useState({});
    const [permissionsListDisabled,setPermissionsListDisabled] = useState({});
    const [fieldsError, setFieldsError] = useState({});
    const [formError, setFormError] = useState(false);
    const [eventDate, setEventDate] = useState('');
    const [eventComment, setEventComment] = useState('');
    const [showErrorEventDate,setShowErrorEventDate] = useState(false);
    const [temporalPkReferer, setTemporalPkReferer] = useState(-1);
    
    const [fieldsValues, setFieldsValues] = useState({});

    useEffect(()=>{                  
        let ret = {};
        if (props.fields.length>0) {
            let fieldsNames = {};
            for (let field of props.fields) {
                fieldsNames[field.name]="";
            }            
            ret = fieldsNames;        
        } else {
            ret = {};
        }
        setFieldsValues(ret);        
    },props.fields);

    useEffect(()=>{                        
    },[props.form])

    useEffect(() => {        
        onSaveCount(props.saveCount);
    }, [props.saveCount]);

    useEffect(() => {        
        
        if (props.mode==='update') {            
            bindForm();            
        }
        if (props.mode==='new') {            
            let fieldsNames = {};
            for (let field of props.fields) {
                if (field.type==='checkbox') {
                    fieldsNames[field.name]=false;
                } else {
                    fieldsNames[field.name]="";
                }                
            }
            setFieldsValues(fieldsNames);
        }
    },[props.mode]);

    useEffect(() => {        
        
    }, [props.count]);


    useEffect(() => {
        
        let valuesTemp = {};
        let permissionsDisabledTemp = {};
        let permissionsListDisabledTemp = {};
        for (let i=0; i<props.fields.length;i++) {
            //valuesTemp[props.fields[i].name]=props.fields[i].name;
            if (props.fields[i].type==='checkbox') {
                valuesTemp[props.fields[i].name]=false;
            } else {
                valuesTemp[props.fields[i].name]="";
            }
            
            if (props.fields[i].permissions.edit[props.parentType].includes(parseInt(props.parentTypeSubType))===false) {
                permissionsDisabledTemp[props.fields[i].name]=true;                
            } else {
                permissionsDisabledTemp[props.fields[i].name]=false;                
            }
            if (props.fields[i].permissions.list[props.parentType].includes(parseInt(props.parentTypeSubType))===true) {
                permissionsListDisabledTemp[props.fields[i].name]=false;                
            } else {
                permissionsListDisabledTemp[props.fields[i].name]=true;                
            }
        }

        setFieldsValues(valuesTemp);
        setPermissionsDisabled(permissionsDisabledTemp);
        setPermissionsListDisabled(permissionsListDisabledTemp);

        if (props.withDefault) {            
            bindFormDefault();
        }        
    }, [props.fields]);

    useEffect(() => {        
        
        if (formError || props.mode==='update') {
            //const data = new FormData(form.current)
            validateForm();
        }        
        
        
    }, [fieldsValues]);

    useEffect(()=>{
        setTemporalPkReferer((new Date().valueOf())*-1);
    },props.show)

    
    const onChangeEventDate = (value) => {
        setEventDate(value);
    }
    const onChangeEventComment = (value) => {
        setEventComment(value);
    }

    const form = useRef(null)

    const onSaveCount = (count) => {        
        
        if (count>0) {                        
            submit();
        }
        
    }   

    const bindForm = () => {
        fetch('http://127.0.0.1:2021/objects/get/'+props.objectTypeBindForm+'/'+props.pk, { 
            method: 'GET',             
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": userToken
                },            
        })
          .then(async function(res) {
            let resJson = await res.json()  
            
            let values = {};
            let valuesTemp = Object.entries(resJson.values);
            // TODO: ajustar tipado en base de datos
            for (let value of valuesTemp) {
                // fix checkbox
                if (value[1]==="false") {value[1]=false}
                if (value[1]==="true") {value[1]=true}
                values[value[0]]=value[1];
            }                                    
            setFieldsValues(values);            
            /*setTimeout(function() {
                //const data = new FormData(form.current)                                
                let error = validateForm();
            },5000)*/
            
          })
    }

    const bindFormDefault = () => {
        
        fetch('http://127.0.0.1:2021/objects/get/'+props.objectTypeBindForm+'/'+props.pkDefault, { 
            method: 'GET',             
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": userToken
                },            
        })
          .then(async function(res) {
            let resJson = await res.json()  
            setFieldsValues(resJson.values);
          })
    }

    const recursiveSearchFieldToReset = (field) => {             
        let valuesRet = [];
        // verifico si existe un campo anidado para limpiarlo
        for (let i=0; i<props.fields.length;i++) {            
            if (props.fields[i].fkField===field) {
                
                valuesRet.push(props.fields[i].name);
                
                let ret = recursiveSearchFieldToReset(props.fields[i].name);                
                
                if (ret.length>0) {
                    for (let fieldTemp of ret) {
                        valuesRet.push(fieldTemp);
                    }
                    
                }
                
            }
        }
        return valuesRet;
    }

    const onChange = (field,val) => {        
        
        let valuesTemp = {...fieldsValues};                
        
        // verifico si existe un campo anidado para limpiarlo
        let ret = recursiveSearchFieldToReset(field);                
        if (ret.length>0) {
            for (let fieldToReset of ret) {                
                valuesTemp[fieldToReset]='';                
            }
        }
        
        valuesTemp[field]=val;                
        setFieldsValues(valuesTemp);
        
    }

    const onChangeMultiselect = (field,val) => {        
        
        let valuesTemp = {...fieldsValues};                
                
        let valTemp = [];
        for (let item of val) {            
            valTemp.push(item.id);
        }

        valuesTemp[field]=valTemp.join(",");                
        setFieldsValues(valuesTemp);
        
    }

    const validateForm = () => {
        
        let flagError = formError;
        let flagErrorTemp = false;
        let fieldErrorTemp = {...fieldsError};
        for (let field of props.fields) {
            
            fieldErrorTemp[field.name]=false;
            if (field.validations.mandatory) {
                if (fieldsValues[field.name]==null) fieldsValues[field.name]='';
                if (fieldsValues[field.name]==='') {
                    flagError = true;
                    fieldErrorTemp[field.name]=true;
                    flagErrorTemp = true;
                }
            }
        }
        
        setFieldsError(fieldErrorTemp);
        setFormError(flagError);
        return flagErrorTemp;
    }
    
    const submit = () => {
        //e.preventDefault()        
        if (props.eventMode) {
            if (eventDate==='') {
                setShowErrorEventDate(true);
                return;
            } else {
                setShowErrorEventDate(false);
            }
        }

        //const data = new FormData(form.current)
        let error = validateForm();
        let fieldsValuesTemp = {};
        for (let fieldName of Object.keys(fieldsValues)) {
            for (let i=0; i<props.fields.length;i++) {            
                if (props.fields[i].name===fieldName) {
                    if (props.fields[i].onlyEvent)  {

                    } else {
                        fieldsValuesTemp[fieldName] = fieldsValues[fieldName];
                    }
                }
            }
            
        }
        
        // @ata, comentar linea 324 para puentear errores.
        if (error) return;
        
        let dataObj = {}

        if (props.mode==='new') {
            dataObj = {
                values:fieldsValuesTemp, 
                headerValues:{
                    date:eventDate,
                    comment:eventComment
                }, 
                action: 'add', 
                pk:null, 
                objectType:props.objectType, 
                pkObjectType: props.pkObjectType, 
                pkReferer: props.pkReferer, 
                holder: props.parentId, 
                temporalDetailParentId: temporalPkReferer, 
                globalModel:props.globalModel};
        }

        if (props.mode==='update') {
            dataObj = {
                values:fieldsValuesTemp, 
                headerValues:{
                    date:eventDate,
                    comment:eventComment
                }, 
                action: 'update', 
                pk:props.pk, 
                objectType:props.objectType, 
                pkObjectType: props.pkObjectType, 
                pkReferer: props.pkReferer, 
                holder: props.parentId, 
                temporalDetailParentId: temporalPkReferer, 
                globalModel:props.globalModel 
            };
        }
        

        fetch('http://127.0.0.1:2021/objects/add', { 
            method: 'POST', 
            body: JSON.stringify(dataObj),
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": userToken
                },            
        })
          .then((res)=>{
              if (res.status===200) {                
                props.onUpdated();
                if (props.updateFromChildMassive) {
                    props.updateFromChildMassive(props.pk);
                } 
              }             
          })
          //.then(json => setUser(json.user))*/
      }

  const renderField = (field,xs) => {
    return (
        <Grid xs={xs} item>
        <h3>{field.label}
        {(field.validations.mandatory) &&
            <a> (*)</a>
        }
        </h3>                
        
        {(field.type==='text') &&
            <TextField disabled={permissionsDisabled[field.name]} name={field.name} value={fieldsValues[field.name]} onChange={(val)=>(onChange(field.name,val.target.value))} placeholder={'Ingrese '+field.name} />
        }
        {(field.type==='file') &&
            <div>
                <h5 style={{fontSize:"10px"}}>{fieldsValues[field.name]}</h5>
                <TextField type="file" disabled={permissionsDisabled[field.name]} name={field.name} onChange={(val)=>(onChange(field.name,val.target.value))} placeholder={'Ingrese '+field.name} />
            </div>                    
        }
        {(field.type==='checkbox') &&
            <Checkbox disabled={permissionsDisabled[field.name]} name={field.name} checked={fieldsValues[field.name]} onChange={(val)=>(onChange(field.name,val.target.checked))} placeholder={'Ingrese '+field.name}/>
            
        }
        {(field.type==='date') &&
            <TextField type="date" disabled={permissionsDisabled[field.name]} name={field.name} value={fieldsValues[field.name]} onChange={(val)=>(onChange(field.name,val.target.value))} placeholder={'Ingrese '+field.name} />
            
        }
        {(field.type==='color') &&
            <ColorPicker
            style={{fontWeight:'bold'}}                    
            defaultValue='#####'                    
            disabled={permissionsDisabled[field.name]} name={field.name} value={fieldsValues[field.name]} onChange={(val)=>(onChange(field.name,val))} placeholder={'Ingrese '+field.name}                    
            />
        }

        {(field.type==='detail') &&
            <BasicfieldCollection      
                pkObjectType={field.detailModel}       
                objectType={props.objectType+'_detail'} 
                parentType={props.parentType} 
                parentTypeSubType={props.parentTypeSubType} 
                parentId={props.parentId} 
                fields={field.detailFields}
                pk={props.pk}
                model={props.pkObjectType}
                globalModel={props.globalModel}
                temporalPkReferer={temporalPkReferer}
          />
        }
        
        {(field.type==='select') &&                                        
            <FormControl className={classes.formControl}>
                <InputLabel id={'demo-controlled-open-select-label-'+field.name}>{field.label}</InputLabel>
                <Select  disabled={permissionsDisabled[field.name]}
                    name={field.name} 
                    labelId={'demo-controlled-open-select-label-'+field.name}
                    value={fieldsValues[field.name]}                             
                    id="select" 
                    placeholder={'Seleccione '+(field.label)} 
                    onChange={(val)=>(onChange(field.name,val.target.value))}
                >
                    <MenuItem value="">
                        <em>Deseleccionar</em>
                    </MenuItem>                        
                {field.values.map((item) =>(
                    (field.fkField && item.fk===fieldsValues[field.fkField]) &&
                    <MenuItem value={item.key}>{item.label}</MenuItem>                        
                ))}
                {field.values.map((item) =>(
                    (!field.fkField) &&
                    <MenuItem value={item.key}>{item.label}</MenuItem>                        
                ))}                                        
                </Select>
            </FormControl>
        }
            

        {(field.type==='multiselect') &&                                        
            <Multiselect disabled={permissionsDisabled[field.name]}
            name={field.name} 
            placeholder={'Seleccionar'} 
            options={field.values.map((item)=>{
                return {name:item.label,id:item.key}
            })} // Options to display in the dropdown
            // TODO: separar id de label
            selectedValues={(fieldsValues[field.name]==='' || fieldsValues[field.name]===undefined)?[]:fieldsValues[field.name].split(",").map((item)=>{
                return {name:item,id:item}
            })} // Preselected value to persist in dropdown
            onSelect={(val)=>(onChangeMultiselect(field.name,val))} // Function will trigger on select event
            onRemove={(val)=>(onChangeMultiselect(field.name,val))} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            />
                                
        }
        {(fieldsError[field.name] && formError) &&
            <label style={{color:'red'}}><br/>Completar</label>
        }
        <br/><br/>

        
    </Grid>
    )
  }  

  
  return (
    <div className="App">
    {(props.show) &&
    <div>
    <form ref={form} >
    {(props.formMode==='vertical') &&
    <Grid container justifyContent="center" className={classes.root} spacing={2}>
      
      <Grid item xs={8}>
        <Grid container justifyContent="left" spacing={spacing}>         
        {
            (props.form) == null &&
                props.fields.map((field)=>(
                    ((field.onlyEvent===undefined || !field.onlyEvent) && permissionsListDisabled[field.name]==false) &&             
                        renderField(field,4)
                    ))           
        }
        {
            (props.form) != null &&
                props.form.config.map((step)=>{
                    return (
                    <Paper elevation="3">
                        {step.title}
                        {
                            step.sections.map((section)=>{
                                return (
                                    <a>
                                    <h1>{section.title}</h1>
                                    {
                                        props.fields.map((field)=>(
                                            (
                                                (section.fields.includes(field.name)) &&
                                                (field.onlyEvent===undefined || !field.onlyEvent) && permissionsListDisabled[field.name]===false) &&             
                                                renderField(field,12)
                                            )
                                        )            
                                    }
                                    </a>
                                    )
                            })
                        }
                    </Paper>                    
                    )
            })                               
        }        
        
            {/* {(props.eventMode) &&
                <Grid xs="4" item>
                    <h3>{'Fecha del Evento'} (*)</h3>
                    <TextField type="date" name={'eventDate'} value={eventDate} onChange={(val)=>(onChangeEventDate(val.target.value))} placeholder={'Ingrese Fecha del Evento'} />
                    {(showErrorEventDate) &&
                        <label style={{color:'red'}}><br/>Completar</label>
                    }
                </Grid>
            }
            {(props.eventMode) &&
                <Grid xs="4" item>
                    <h3>{'Comentarios'}</h3>
                    <TextField type="text" name={'eventComment'} value={eventComment} onChange={(val)=>(onChangeEventComment(val.target.value))} placeholder={'Ingrese Comentario'} />
                </Grid>
            } */}

        </Grid>
      </Grid>      
    </Grid>
    }
    {(props.formMode==='horizontal') && 
    <div>
    {(props.showHeader) &&
    <Grid container
    wrap="nowrap" // --> add this line to disable wrap
    //style={} // --> add scrollbar when overflow
    justifyContent="center" className={classes.root} spacing={0}>
        {props.fields.map((field)=>(
            ((field.onlyEvent===undefined || !field.onlyEvent) && permissionsListDisabled[field.name]===false) && 
            <Grid item style={{borderStyle:"solid",borderWidth:"1px",padding:"5px",width:'300px',maxWidth:'180px',backgroundColor:(field.validations.mandatory?'Orange':"Highlight")}}>
                    {field.label} 
                    {
                        (field.validations.mandatory) &&
                        <a>&nbsp;(*)</a>
                    }
            </Grid>
        ))
        }
    </Grid>
    }
    <Grid container
    wrap="nowrap" // --> add this line to disable wrap
    //style={} // --> add scrollbar when overflow
    justifyContent="center" className={classes.root} spacing={0}>
        {
          props.fields.map((field)=>(
            ((field.onlyEvent===undefined || !field.onlyEvent) && permissionsListDisabled[field.name]===false) && 
            <Grid item style={{borderStyle:"solid",borderWidth:"1px",padding:"5px",width:'300px',maxWidth:'180px'}}>
                
                {(field.validations.mandatory) &&
                    <a>(*)</a>
                }
                {(field.type==='text') &&
                    <TextField disabled={permissionsDisabled[field.name]} name={field.name} value={fieldsValues[field.name]} onChange={(val)=>(onChange(field.name,val.target.value))} placeholder={'Ingrese '+field.name} />
                }
                {(field.type==='file') &&
                    <div>
                        <h5 style={{fontSize:"10px"}}>{fieldsValues[field.name]}</h5>
                        <TextField type="file" disabled={permissionsDisabled[field.name]} name={field.name} onChange={(val)=>(onChange(field.name,val.target.value))} placeholder={'Ingrese '+field.name} />
                    </div>                    
                }
                {(field.type==='checkbox') &&
                    <Checkbox disabled={permissionsDisabled[field.name]} name={field.name} checked={fieldsValues[field.name]} onChange={(val)=>(onChange(field.name,val.target.checked))} placeholder={'Ingrese '+field.name}/>
                    
                }
                {(field.type==='date') &&
                    <TextField type="date" disabled={permissionsDisabled[field.name]} name={field.name} value={fieldsValues[field.name]} onChange={(val)=>(onChange(field.name,val.target.value))} placeholder={'Ingrese '+field.name} />
                    
                }
                {(field.type==='color') &&
                    <ColorPicker                    
                    style={{fontWeight:'bold'}}
                    defaultValue='#####'                    
                    disabled={permissionsDisabled[field.name]} name={field.name} value={fieldsValues[field.name]} onChange={(val)=>(onChange(field.name,val))} placeholder={'Ingrese '+field.name}                    
                    />
                }
                {(field.type==='select') &&                                        
                    <FormControl className={classes.formControl}>
                        <InputLabel id={'demo-controlled-open-select-label-'+field.name}>{field.label}</InputLabel>
                        <Select disabled={permissionsDisabled[field.name]}
                            name={field.name} 
                            labelId={'demo-controlled-open-select-label-'+field.name}
                            value={fieldsValues[field.name]}                             
                            id="select" 
                            placeholder={'Seleccione '+(field.label)} 
                            onChange={(val)=>(onChange(field.name,val.target.value))}
                        >
                            <MenuItem value="">
                                <em>Deseleccionar</em>
                            </MenuItem>                        
                        {field.values.map((item) =>(
                            (field.fkField && item.fk===fieldsValues[field.fkField]) &&
                            <MenuItem value={item.key}>{item.label}</MenuItem>                        
                        ))}
                        {field.values.map((item) =>(
                            (!field.fkField) &&
                            <MenuItem value={item.key}>{item.label}</MenuItem>                        
                        ))}                                        
                        </Select>
                    </FormControl>
                }
                {(field.type==='multiselect') &&                                        
                    <Multiselect disabled={permissionsDisabled[field.name]}
                    name={field.name} 
                    placeholder={'Seleccionar'} 
                    options={field.values.map((item)=>{
                        return {name:item.label,id:item.key}
                    })} // Options to display in the dropdown
                    // TODO: separar id de label
                    selectedValues={(fieldsValues[field.name]==='' || fieldsValues[field.name]===undefined)?[]:fieldsValues[field.name].split(",").map((item)=>{
                        return {name:item,id:item}
                    })} // Preselected value to persist in dropdown
                    onSelect={(val)=>(onChangeMultiselect(field.name,val))} // Function will trigger on select event
                    onRemove={(val)=>(onChangeMultiselect(field.name,val))} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    />
                                        
                }                
                {(fieldsError[field.name] && formError) &&
                    <label style={{color:'red'}}><br/>Completar</label>
                }
                
            </Grid>
            
            ))
        }
        
    </Grid>
    </div>
    }
    {(props.formMode==='vertical') &&
    <div>
        <Button color="primary" onClick={()=>{submit();}} variant="contained">Guardar</Button>
        &nbsp;
        <Button color="secondary" onClick={()=>{props.onCanceled()}} type="button" variant="contained">Cancelar</Button>      
    </div>
    }
    </form>
            
      </div>
    }      
    </div>
  );
}

export default BasicForm;

import useGlobalContext from '../../Hooks/useGlobalContext';
import { LOGIN } from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect } from "react";
import { ThemeContext } from 'styled-components';
import useThemeContext from '../../Hooks/useThemeContext';

function Logout() {
  let history = useHistory();
  const { logout } = useGlobalContext();
  const { setLogoTenant } = useThemeContext();
  const host = window.location.hostname;
  const subdomain = host.split(".")[0];

  useEffect(() => {
    logout();
    history.push(LOGIN);
    if (subdomain === "app") {
      setLogoTenant(undefined);
    }
  }, [history, logout]);

  return null;
}

export default Logout;
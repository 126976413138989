import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { greenTheme } from '../Layout/theme/greenTheme';
import { pinkTheme } from '../Layout/theme/pinkTheme';
import { blueTheme } from '../Layout/theme/blueTheme';
import { createTheme } from "@mui/material/styles";
import { typography, breakpoints } from "../Layout/theme/commonTheme";
import { fetchTenantTheme } from "../services/tenant";
import { assetTypeDict } from '../constants';
import { isThemeEqual } from '../Helpers/theme';

export const ThemeContext = createContext();

export default function ThemeContextProvider({ children }) {
  const [activeTheme, setActiveTheme] = useState(greenTheme);
  const [menuMode, setMenuMode] = useState('HOME');
  const [activeAsset, setActiveAsset] = useState(-1);
  const [themes, setThemes] = useState({ greenTheme, blueTheme });
  const [logoTenant, setLogoTenant] = useState(undefined);

  const setActiveThemeFromAsset = (n) => {
    setActiveAsset(n);
    if (menuMode === "HOME") {
      if (n === 11) {
        setActiveTheme(pinkTheme);
      } else if (n === 12) {
        setActiveTheme(themes.greenTheme);
      } else {
        setActiveTheme(themes.greenTheme);
      }
    }
  };

  const applyDynamicThemes = async () => {
    const host = window.location.hostname;
    // Para testear descomentar el hostDos y probar con app o mtr
    /* const hostDos = 'a3.dev.origino.io/' */
    const subdomain = host.split(".")[0];
    if (subdomain !== "app") {
      try {
        const themeData = await fetchTenantTheme(subdomain);
        if (themeData.name === subdomain) {
          const themeContent = themeData.theme.theme;
          const dynamicGreenTheme = createTheme({
            ...themeContent.greenTheme,
            typography: typography,
            breakpoints: breakpoints,
          });
          const dynamicBlueTheme = createTheme({
            ...themeContent.blueTheme,
            typography: typography,
            breakpoints: breakpoints,
          });

          setLogoTenant(themeData.theme.logo.url)
          setThemes({ greenTheme: dynamicGreenTheme, blueTheme: dynamicBlueTheme });
        }
      } catch (error) {
        setThemes({ greenTheme: greenTheme, blueTheme: blueTheme });
        console.error("Error fetching dynamic themes:", error);
      }
    }
  };

  const setDynamicThemeFromEstablishment = async (active) => {
    try {
      const response = await fetchTenantTheme(active);
      const themeContent = response.theme;
  
      const dynamicGreenTheme = createTheme({
        ...themeContent.greenTheme,
        typography: typography,
        breakpoints: breakpoints,
      });

      if (!isThemeEqual(activeTheme, dynamicGreenTheme)) {
        if (menuMode === 'PREHOME') {
          setActiveTheme(blueTheme);
          setLogoTenant(undefined)
        } else if (menuMode === 'HOME'){
          setActiveTheme(dynamicGreenTheme);
          setLogoTenant(themeContent.logo.url)
        }
        setThemes(prev => ({ ...prev, greenTheme: dynamicGreenTheme}));
      }
    } catch (error) {
      console.error("Error al aplicar tema dinámico:", error);
    }
  };

  const setThemeFromEstablishment = async (establishmentSubtype) => {
    const host = window.location.hostname;
    const subdomain = host.split(".")[0];
    if (subdomain === "app" && assetTypeDict[establishmentSubtype]) {
      const assetTypeKey = assetTypeDict[establishmentSubtype];
      await setDynamicThemeFromEstablishment(assetTypeKey);
    } else if (subdomain === "app" && !assetTypeDict[establishmentSubtype]) {
      if (!isThemeEqual(activeTheme, greenTheme)) {
        setThemes({ greenTheme: greenTheme, blueTheme: blueTheme });
        setLogoTenant('')
        if (menuMode === 'PREHOME') {
          setActiveTheme(blueTheme); 
        } else if (menuMode === 'HOME'){
          setActiveTheme(greenTheme);
        }
      }
    }
  };

  useEffect(() => {
    applyDynamicThemes();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('MENU_MODE')) {
      setMenuMode(localStorage.getItem('MENU_MODE'));
    }
  }, []);
  useEffect(() => {
    if (menuMode === 'PREHOME') {
      setActiveTheme(themes.blueTheme);
    } else if (menuMode === 'HOME'){
      setActiveThemeFromAsset(activeAsset);
    }
    localStorage.setItem('MENU_MODE', menuMode);
  }, [menuMode, themes]);

  const swithMenuMode = (mode) => {
    setMenuMode(mode);
  }

  const value = {
    
    logoTenant,
    setLogoTenant,
    activeTheme, 
    setActiveTheme,
    menuMode, 
    swithMenuMode,
    setActiveThemeFromAsset,
    setDynamicThemeFromEstablishment,
    setThemeFromEstablishment
  };



  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

ThemeContextProvider.propTypes = {
  children: PropTypes.object,
};

import { Autocomplete, Button, Checkbox, Divider, Grid, Step, StepButton, Stepper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { dateSanitizer } from '../../Helpers/dateFormater';
import iconNotStampedNFT from '../../Images/nft_notstamped.png';
import iconStampedNFT from '../../Images/nft_stamped.png';
import { DataGridOracles } from './DataGridOracles';
import ModalErrorInfo from '../../Components/Feature/ModalErrorInfo';
import { ToastContainer, toast } from 'react-toastify';
import { ASSETS_ORACLES } from '../../constants/paths';
import { useHistory } from 'react-router-dom';

const AssetsOracles = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  const { selectedAsset, selectedBox } = useGlobalContext();
  const [rowsAssets, setRowsAssets] = useState([]);
  const [columnsAssets, setColumnsAssets] = useState([]);
  const [rowsOracles, setRowsOracles] = useState([]);
  const [columnsOracles, setColumnsOracles] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [oraclesData, setOraclesData] = useState([]);
  const [selectedAssetsIds, setSelectedAssetsIds] = useState([]);
  const [selectedOraclesIds, setSelectedOraclesIds] = useState([]);
  const [availablesOraclesIds, setAvailablesOraclesIds] = useState([]);
  const [linkAssetOracle, setLinkAssetOracle] = useState([]);
  const [oracleValue, setOracleValue] = useState(null);
  const [disableNext, setDisableNext] = useState(false);
  const [loadingOracle, setLoadingOracle] = useState(false);
  const [loadingAssetsTable, setLoadingAssetsTable] = useState(false);
  const [loadingOracleTable, setLoadingOracleTable] = useState(false);
  const [loadingSaveOracle, setLoadingSaveOracle] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [messageError, setMessageError] = useState({
    message: null,
    data: null
  });

  const steps = [
    t('assetsoracles.steps.S1'),
    t('assetsoracles.steps.S2'),
    t('assetsoracles.steps.S3'),
    t('assetsoracles.steps.S4'),
    t('assetsoracles.steps.S5'),
  ]

  useEffect(() => {
    let models = globalContext.getSessionDataByKey('models');

    let attributes = []
    if (selectedAsset.id !== -1) {
      if (models != null) {
        attributes = models[selectedAsset.id].attributes.filter(att => att.hideInList === false)
        setColumnsAssets(attributes)
      }
    }

    if (attributes.length === 0) return;
    let ret = [];

    ret.push({ field: 'id', headerName: 'Id', hide: true});

    for (let field of attributes) {
      let flagAdd = false;
      if (field.permissions.list['box'].includes(parseInt(selectedBox.subtype)) === true) {
        flagAdd = true;
      }

      if (flagAdd) {
        if (field.type === 'file') {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 190,
            renderCell: (params) => {
              let image = <></>
              if (params.row[field.name] != null && params.row[field.name] !== "") {
                let file = JSON.parse(params.row[field.name]);
                if (file?.originalName) {
                  let originalNameSplit = file.originalName.split(".");
                  if (['JPG', 'PNG', 'JPEG', 'TIFF', 'BMP'].includes(originalNameSplit[originalNameSplit.length - 1].toUpperCase())) {
                    image = <img style={{ maxWidth: "30px", borderStyle: "dotted", borderWidth: "1px", verticalAlign: "middle", marginRight: "5px" }} src={file.publicFileURI} alt=""></img>
                  }
                }
                const onDownload = () => {
                  const link = document.createElement("a");
                  link.href = file.publicFileURI;
                  link.click();
                };
                if (params.row[field.name] != null && params.row[field.name] !== "") {
                  return (
                    <div>
                      {image}
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        size="small"
                        startIcon={<FontAwesomeIcon icon={faDownload} />}
                        onClick={() => { onDownload() }}
                        style={{ padding: "5px", margin: "5px", fontSize: "12px" }}
                      >
                        {t("largeGrid.button.download")}
                      </Button>

                    </div>
                  )
                }
              }
            }
          })
        } else if (field.type === 'date') {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 150,
            fieldType: field.type,
            valueFormatter: (params) => dateSanitizer(params?.value)
          })
        } else if (field.type === 'nft_link') {
          ret.push({
            field: 'Id', 
            headerName: 'Id',
            width: 100,
            valueGetter: (params) => params.row['id'],
            renderCell: (params) => {
              if (params.row[field.name] === "" || params.row[field.name] == null) {
                return <>
                  <img alt=""
                    style={{ width: "30px", marginLeft: "2px", marginRight: "5px", cursor: "pointer" }} src={iconNotStampedNFT}
                  />
                  {params.row['id']}
                </>;
              } else {
                return <>
                  <a target="_BLANK" style={{ marginLeft: "2px", marginRight: "5px" }} href={params.row[field.name]} rel="noreferrer">
                    <img alt="" style={{ width: "30px" }} src={iconStampedNFT} /></a>{params.row['id']}
                </>;
              }
            }
          })
        } else if (field.type === 'checkbox' ) {
          ret.push({
            field: field.name, headerName: field.label, sortable: true, width: '120', renderCell: (
              (params) => {
                return (
                  <Checkbox
                    sx={{width: '100%'}}
                    id={params.id}
                    disabled={true}
                    checked={params.row[field.name] === "true" ? true : false}
                  />
                )
              }
            )
          })
        } else if (field.type === 'select' && field.buttonInGrid) {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 140,
            justifyContent: 'center',
            renderCell: (params) => {
              let objAssetColor = null

              field.colorsButton.forEach(asset => {
                if (objAssetColor === null && asset.label === params.row[field.name]) {
                  objAssetColor = asset.color
                }
              })

              if(!objAssetColor) objAssetColor = '#76CC81'

              return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                <Button
                  style={{
                    padding: "0px",
                    margin: "0px",
                    fontSize: "11px",
                    paddingTop: '0.2rem',
                    background: objAssetColor,
                    color: 'black',
                    height: '25px',
                    width: '80%'
                  }}
                  size='medium'
                  variant="contained"
                >
                  {params.row[field.name]}
                </Button>
              </div>
            }
          })
        } else if (field.type === 'none') {
          // field.type none no muestra la columna en la tabla de assets.
        } else {
          ret.push({
            field: field.name,
            headerName: field.label,
            width: 150
          })
        }
      }
    }
    setColumnsAssets(ret);
  }, [globalContext, selectedAsset.id, selectedBox.subtype]);

  useEffect(() => {
    if(selectedAsset.id) {
      setLoadingOracle(true)
      ApiFetch(
        `oracles/${selectedAsset.id}`,
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            setOraclesData(await res.json());
          }
          setLoadingOracle(false)
        },
        (error) => {
          console.log('error oracles', error);
          setLoadingOracle(false)
        },
        {
          method: 'GET',
        }
      );
    }
  },[selectedAsset, globalContext])

  useEffect(() => {
    if(activeStep === 1 && oracleValue) {
      if (rowsAssets.length === 0 && selectedAsset.id && selectedBox.id) {
        setLoadingAssetsTable(true)
        ApiFetch(
          `oracles/assets/${selectedAsset.id}/${selectedBox.id}/${oracleValue}/0`,
          globalContext,
          true,
          async (res) => {
            if (res.status === 200) {
              setRowsAssets(await res.json());
            }
            setLoadingAssetsTable(false)
          },
          (error) => {
            console.log('error oracles', error);
            setLoadingAssetsTable(false)
          },
          {
            method: 'GET',
          }
        );
      }
      if (rowsOracles.length === 0 && columnsOracles.length === 0) {
        setLoadingOracleTable(true)
        ApiFetch(
          `oracles/idsOracles/${selectedBox.id}/${oracleValue}`,
          globalContext,
          true,
          async (res) => {
            if (res.status === 200) {
              const data = await res.json();
              setColumnsOracles(data.columns)
              setRowsOracles(data.values)
            }
            setLoadingOracleTable(false)
          },
          (error) => {
            console.log('error oracles', error);
            setLoadingOracleTable(false)
          },
          {
            method: 'GET',
          }
        );
      }
    }
  },[activeStep])

  useEffect(() => {
    if(activeStep === 0) {
      oracleValue ? setDisableNext(false) : setDisableNext(true)
    } else if (activeStep === 1) {
      selectedAssetsIds?.length > 0 ? setDisableNext(false) : setDisableNext(true)
    } else if (activeStep === 2) {
      if (
        selectedAssetsIds && selectedOraclesIds && 
        selectedAssetsIds?.length === selectedOraclesIds?.length 
      ) { 
        setDisableNext(false)
        setAvailablesOraclesIds(selectedOraclesIds)
      } else setDisableNext(true)
    } else if (activeStep === 3) {
      availablesOraclesIds?.length === 0 ? setDisableNext(false) : setDisableNext(true)
    }
  },[activeStep, oracleValue, selectedAssetsIds, selectedOraclesIds, availablesOraclesIds])

  useEffect(()=>{ 
    activeStep === 3 && 
    setAvailablesOraclesIds(availablesOraclesIds.filter(value => 
      !linkAssetOracle.some(obj => Object.values(obj)[0] === value)
    ))
  },[activeStep])

  useEffect(()=>{ 
    setRowsAssets([])
    setRowsOracles([])
    setColumnsOracles([])
    setSelectedAssetsIds([])
    setSelectedOraclesIds([])
    setAvailablesOraclesIds([])
    setLinkAssetOracle([])
  },[oracleValue])

  const handleLinkOracleAsset = (asset, oracle) => {
    setAvailablesOraclesIds(prev => {
      if (oracle !== null) {
        return prev.filter(id => id !== oracle);
      } else {
        const previouslySelectedOracle = linkAssetOracle.find(item => Object.keys(item)[0] === String(asset))?.[asset];
        if (previouslySelectedOracle) {
          return [...prev, previouslySelectedOracle];
        }
        return prev;
      }
    });
    setLinkAssetOracle(prev => {
      const index = prev.findIndex(item => Object.keys(item)[0] === String(asset));
      if (index !== -1) {
        const updatedList = [...prev];
        updatedList[index] = { [asset]: oracle };
        return updatedList;
      } 
      return [...prev, { [asset]: oracle }];
    });
  }

  const handleSaveButton = () => {
    setLoadingSaveOracle(true)
    const values = linkAssetOracle.map(vinculo => {
      const assetId = Object.keys(vinculo)[0];
      const oraculoId = vinculo[assetId];
  
      let oraculo = rowsOracles.find(o => o.id === oraculoId);
      
      return {
        ...oraculo,
        pk: assetId
      };
    })
    
    ApiFetch(
      `oracles/saveOracle/${selectedBox.id}/${oracleValue}/${selectedAsset.id}`,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          toast.success(t('component.oraclesSuccess'), {
            className: 'toast-message-body',
            progressClassName: 'toast-message-progress',
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setLoadingSaveOracle(false)
        history.push({pathname: ASSETS_ORACLES})
      },
      async (error) => {
        const result = await error.json()
        console.log('error oracles', error);
        setLoadingSaveOracle(false)
        setModalError(true)
        setMessageError({message: result.error, data: result.data})
      },
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    );
  }

  return(
    <>
      <ModalErrorInfo
        open={modalError}
        toggleModal={() => {
          setModalError(false);
          setMessageError({message:null, data:null})
        }}
        modalText={
          messageError.message === 'noSoportedOracle' ? t('component.noSoportedOracle') :
          messageError.message === 'dataRepeated' ? messageError.data.map(data => `${t('component.dataRepeated', {code: data.oracleCode, asset: data.asset})}`).join('<br />') :
          messageError.message === 'other' && t('component.comErrorList')
        }
      />
      <ToastContainer/>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} className="mt-2">
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepButton
                  color="inherit"
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Divider variant="middle" sx={{ mt: 4, mb:2 }} />
        </Grid>
        <Grid item xs={12} className="mt-2">
          {activeStep === 0 &&
            <Autocomplete
              size="small"
              options={oraclesData}
              getOptionLabel={(option) => option.label}
              loading={loadingOracle}
              loadingText={t('component.selectField.loading')}
              renderInput={(params) => <TextField {...params} label={t('assetsoracles.fields.selectOracle')} />}
              onChange={(event, value) => setOracleValue(value?.key)}
              isOptionEqualToValue={(option, value) => option.key === value?.key}
              value={oraclesData?.find(option => option.key === oracleValue) || null}
            />
          }
          {activeStep === 1 && 
            <DataGridOracles
              title={`${t("largeGrid.title") + selectedAssetsIds.length}`}
              rows={rowsAssets}
              columns={columnsAssets} 
              type='assets'
              checkboxSelect={true}
              onSelection={(ids)=>{setSelectedAssetsIds(ids); setLinkAssetOracle([])}}
              selectionModel={selectedAssetsIds}
              loading={loadingAssetsTable}
            />
          }
          {activeStep === 2 && 
            <DataGridOracles
              title={`${t("assetsoracles.largeGrid.titleToSelectOracles")+selectedOraclesIds.length} - ${t("assetsoracles.largeGrid.titleToSelect") + selectedAssetsIds.length}`} 
              rows={rowsOracles}
              columns={columnsOracles} 
              type='oracles'
              checkboxSelect={true}
              onSelection={(id) => {setSelectedOraclesIds(id); setLinkAssetOracle([])}}
              selectionModel={selectedOraclesIds}
              loading={loadingOracleTable}
            />
          }
          {activeStep === 3 && 
            <DataGridOracles
              title={`${t("assetsoracles.largeGrid.titleToLink")+availablesOraclesIds.length}`} 
              rows={rowsAssets.filter(asset => selectedAssetsIds.includes(asset.id))}
              columns={[
                {
                  field: 'selectOracle',
                  headerName: 'Seleccionar Cod. Oráculo',
                  width: 200,
                  renderCell: (params) => (
                    <Autocomplete
                      options={selectedOraclesIds.filter(selectOracle => availablesOraclesIds.some(availableOracle => availableOracle === selectOracle))}
                      value={
                        params.value ??
                        (linkAssetOracle.find(asset => params.id === Object.keys(asset)[0]) 
                          ? Object.values(linkAssetOracle.find(asset => params.id === Object.keys(asset)[0]))[0] 
                          : null)
                      }
                      onChange={(event, newValue) => handleLinkOracleAsset(params.id, newValue)}
                      sx={{
                        width:'100%',
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          size="small"
                          label={'Codigo'}
                          value={params.value}
                        />
                      }
                    />
                  ),
                },
                ...columnsAssets
              ]}
              type='linkAssetOracle'
              checkboxSelect={false}
              loading={false}
            />
          }
          {activeStep === 4 && 
            <DataGridOracles
              rows={linkAssetOracle.map(vinculo => {
                const assetId = Object.keys(vinculo)[0];
                const oraculoId = vinculo[assetId];
            
                const asset = rowsAssets.find(a => a.id === assetId);
                let oraculo = rowsOracles.find(o => o.id === oraculoId);
                oraculo = {
                  ...oraculo,
                  id_oracle: oraculo.id
                }
                return {
                  ...asset,
                  ...oraculo,
                  id:assetId
                };
              })}
              columns={[
                ...columnsAssets, 
                ...columnsOracles.map(column =>
                  column.field === "id" ? {
                    ...column,
                    field: 'id_oracle',
                    headerName: 'ID Oráculo',
                  } : column)
              ].filter(column => !column.hide)} 
              type='oracles'
              checkboxSelect={false}
              loading={false}
            />
          }
        </Grid>
        <Divider variant="middle" sx={{mb:3}} />
        <Grid container justifyContent="center" alignItems="center">
          <Button
            sx={{ ml: 1 }}
            variant="outlined"
            onClick={() => history.push({pathname: ASSETS_ORACLES})}
          >
            {t('assetsoracles.button.cancel')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="outlined"
            onClick={() => setActiveStep(activeStep - 1)}
            disabled={activeStep === 0 || loadingSaveOracle}
          >
            {t('assetsoracles.button.back')}
          </Button>
          {activeStep < 4 && 
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={() => setActiveStep(activeStep + 1)}
              disabled={disableNext || loadingSaveOracle}
            >
              {t('assetsoracles.button.next')}
            </Button>
          }
          {activeStep === 4 && 
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={handleSaveButton}
              disabled={loadingSaveOracle}
            >
              {t('assetsoracles.button.save')}
            </Button>
          }
        </Grid>
      </Grid>
    </>
  )
}

export default AssetsOracles
import React, { createContext } from "react";
import PropTypes from "prop-types";
import useSignInTheme from "../Hooks/useSignInTheme";
import { signInTheme as defaultSignInTheme } from "../pages/SignIn/signInTheme";

export const PublicThemeContext = createContext();

const PublicThemeProvider = ({ children }) => {
  const dynamicSignInTheme = useSignInTheme();

  const theme = dynamicSignInTheme || defaultSignInTheme;

  return (
    <PublicThemeContext.Provider value={theme}>
      {children}
    </PublicThemeContext.Provider>
  );
};

PublicThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicThemeProvider;

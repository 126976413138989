
const eventFormatter = (models, objectType) => {
  let fieldsEventsTemp = {}
  let permissionsGlobal = []

  models?.events?.forEach(event => {
      let fieldsEventTemp = []
      if (event.lotMode === true || objectType === "ASSET") {
        const allFieldsEvent = (event.auxiliarFields) ?
          event.fields.concat(event.auxiliarFields)
          : event.fields;

        allFieldsEvent.forEach((field) => {

          models.attributes.forEach((attribute) => {
              if (attribute.name === field.name) {
              let attributeTemp = { ...attribute };

              if (attributeTemp.validations == null || field.disabled) {
                attributeTemp.validations = {};
              } else {
                attributeTemp.validations = { ...attribute.validations }
              }
              if (event.inheritMandatoryOfAttribute !== true) {
                attributeTemp.validations.mandatory = { value: true, message: "Campo obligatorio" };
              }
              if(field.disabled){
                attributeTemp.disabled = true;
              } else if (field.disabled === false) {
                attributeTemp.disabled = false;
              }

              permissionsGlobal = attribute.permissions;
              fieldsEventTemp.push(attributeTemp);
            }
          })
        });

        // if( event.isTransfer || !event.eventWithoutCommentAndDate ){
        //   fieldsEventTemp.push({
        //     name: "_eventDate_",
        //     modelType: "metadata",
        //     defaultValue: (event.defaultDateIsNow) ? new Date().toISOString() : null,
        //     flagHide: (event.defaultDateIsNow) ? true : false,
        //     permissions: permissionsGlobal,
        //     validations: {
        //       mandatory: { value: true, message: "Campo obligatorio" },
        //       max: {
        //         value: 0,
        //         unit: 'day',
        //         message: `La fecha no puede ser posterior a HOY`,
        //       },
        //     },
        //     label: "Fecha del Evento",
        //     type: "date",
        //   });

        //   fieldsEventTemp.push({
        //     name: "_eventComment_",
        //     modelType: "metadata",
        //     permissions: permissionsGlobal,
        //     validations: {},
        //     label: "Comentarios",
        //     type: "text",
        //   });
        // }

        if (event.isTransfer && objectType === "LOT") {
          fieldsEventTemp.push({
            name: "_transferLot_",
            modelType: "metadata",
            defaultValue: true,
            validations: {},
            permissions: permissionsGlobal,
            label: "Transferir el Tag",
            type: "checkbox",
          });
        }

        fieldsEventsTemp[event.eventType] = fieldsEventTemp;
      }
    }
  )
  return fieldsEventsTemp
}

export default eventFormatter
import addDays from "./dateAddDaysToDate";

export const calculateWarrantyExpirationDate = (selectedAsset, daysToAdd = 180) => {
  const dateBagged = selectedAsset.date_bagged ? new Date(selectedAsset.date_bagged) : null;
  const dateExpired = selectedAsset.date_expired ? new Date(selectedAsset.date_expired) : null;

  if (!dateBagged && !dateExpired) {
    return null;
  }

  const dateBaggedPlus180 = dateBagged ? addDays(dateBagged, daysToAdd) : null;

  let minDate = dateBaggedPlus180 && dateExpired
    ? (dateBaggedPlus180 < dateExpired ? dateBaggedPlus180 : dateExpired)
    : (dateBaggedPlus180 || dateExpired);

  const dayOfWeek = minDate.getDay();
  if (dayOfWeek === 0) {
    minDate = addDays(minDate, 1);
  } else if (dayOfWeek === 6) {
    minDate = addDays(minDate, 2);
  }

  return minDate.toISOString().split("T")[0];
};

export const HOME = '/assets';

export const LOGIN = '/login';

export const LOGIN_SANDBOX = '/access/loginSandbox';
export const ACCESS = '/access';
export const LOGOUT = '/access/logout';
export const USER_CHANGE_PASSWORD = '/access/changePassword'
export const USER_CHANGE_PASSWORD_HASH = '/access/changePassword/:hash'

export const PUBLIC_SIGNIN = '/public/signin';
export const PUBLIC_SIGNUP = '/public/signup';
export const REGISTER = '/register';

export const ASSETS = '/assets';
export const ASSETS_EDIT = '/assets/edit';
export const ASSETS_NEW = '/assets/new';
export const ASSETS_FILE_LOAD = '/assets/file';
export const ASSETS_MANUAL_LOAD = '/assets/file/manual';
export const ASSETS_FILE_VERIFY = '/assets/file/verify/template';
export const ASSETS_MASSIVE_EDIT = '/assets/massive/edit';

export const LOTS = '/lots';
export const LOTS_EDIT = '/lots/edit';
export const LOTS_NEW = '/lots/new';

export const SIGNIN = '/signin';
export const USER_REGISTER = '/signin/users/stepbystep';
export const USER_REGISTER_COMPLETE = '/signin/users/stepbystep/editregister';
export const USER_ACTIVATE = '/signin/users/activate';
export const COMPANY_REGISTER = '/signin/company/stepbystep';
export const COMPANY_ACTIVATE = '/signin/company/activate';
export const BOX_REGISTER = '/signin/box/stepbystep';
export const BOX_ACTIVATE = '/signin/box/activate';

export const CREATE_ACCOUNT = '/signin/cuenta/crear';
export const PERSONAL_ACCOUNT_DATA = '/signin/cuenta/datos_personales';
export const ACCOUNT_LOCATION = '/signin/cuenta/localizacion';
export const EMAIL_SENT = '/signin/email_enviado';
export const COMPANY_CHAINS = '/signin/empresa/cadenas';
export const ESTABLISHMENT_IMAGES = '/signin/establecimiento/imagenes';

export const USER_NEW = '/users/stepbystep';
export const USER_EDIT = '/users/stepbystep/edit';
export const USER_DELEGATIONS_ALL = '/user/delegations/all';
export const USER_DELEGATIONS_COMPANY_ALL = '/user/delegations/company/all';
export const USER_DELEGATIONS = '/user/delegations';

export const COMPANY_ALL = '/company/all';
export const COMPANY_NEW = '/company/stepbystep/new';
export const COMPANY_EDIT = '/company/stepbystep/edit';

export const BOXES = '/boxes';
export const BOXES_ALL = '/boxes/all';
export const BOXES_NEW = '/boxes/stepbystep/new';
export const BOXES_EDIT = '/boxes/stepbystep/edit';

export const EVENTS = '/events';
export const EVENTS_ALL = '/events/all';
export const EVENTS_FILE_LOAD = '/events/file';
export const EVENTS_ASSETS_FILTER = '/events/assets/filter';
export const EVENTS_MASSIVE = '/events/massive';
export const EVENTS_MASSIVE_MANUAL = '/events/massive/manual';

export const TRANSFERS = '/transfers';
export const TRANSFERS_MASSIVE = '/transfers/massive';
export const TRANSFERS_MASSIVE_MANUAL = '/transfers/massive/manual';
export const TRANSFER_LOAD = '/transfer/load';
export const TRANSFER_BOX = '/transfer/box';
export const TRANSFER_ASSETS_FILTER = '/transfer/assets/filter';

export const TRANSFERRED_ASSETS = '/transferredAssets';

export const ASSETS_ORACLES = '/assetsOracles';
export const NEW_ASSETS_ORACLES = '/newAssetOracle';

export const SETTINGS = '/setting';

export const QR_TEMPLATES = '/qrTemplates';
export const QR_IDENTECO = '/qrTemplates/qrIdenteco';
export const QR_IDENTECO_ID = '/qrIdenteco/:id';
export const QR_PRODUKTO_TRUPO = '/qrTemplates/qrProduktoTrupo';
export const QR_PRODUKTO_TRUPO_SELECT = '/qrTemplates/qrProduktoTrupoSelect';
export const QR_PRODUKTO_TRUPO_ID = '/qrProduktoTrupo/:id';
export const QR_IDENTECO_TRUPO = '/qrTemplates/qrIdentecoTrupo';
export const QR_IDENTECO_TRUPO_ID = '/qrIdentecoTrupo/:id';

export const QRS_METRICS = '/qrMetrics';
export const QRS_LOGS = '/qrLogs/:typeQr/:id';

export const ACTIVITIES = '/activities';

export const LOGICAL_DELETED_ASSETS = '/logicalDeletedAssets';

export const REPORTS = '/reports';

export const IMAGES_PATH = '/images'

export const REQUIRED_FIELDS_ERROR = '/requiredFields';
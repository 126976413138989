import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import BaseBasicForm from '../../../Layout/containers/BaseBasicForm';
import BasicFormStepByStep from '../../../Components/Generic/basicFormStepByStep';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { HOME, USER_ACTIVATE } from '../../../constants/paths';
import leftIcon from '../../../Images/stepbystep/lefticons/editar.png'
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import LanguageSelect from '../../../Components/LanguageSelect';

function StepByStepRegistroUserEdit() {
  const { t } = useTranslation();
  const { userData, refreshUserData } = useGlobalContext();

  let history = useHistory();
  const globalContext = useGlobalContext();
  const [fields, setFields] = useState([]);
  const [form, setForm] = useState(null);
  const [isSingUp, setIsSingUp] = useState(false);
  const [prevBind, setPrevBind] = useState({});
  const [activePrevBind] = useState(true);

  const setModels = (pais) => {
    let models = globalContext.getSessionDataByKey('models');
    let modelKey = "DEFAULT"
    let modelLabels = "DEFAULT"
    let attributesModel = []

    if (pais) {
      modelKey = models["userModels"]["basic"]["userTypes"][`${pais}_REGISTER`] ? `${pais}_REGISTER` : "DEFAULT_REGISTER"
      modelLabels = models["userModels"]["basic"]["fieldsLabels"][pais] ? pais : "DEFAULT"
    }

    const activeModel = models["userModels"]["basic"]["userTypes"][modelKey]

    for(const step of activeModel.form.config){
      for(const section of step.sections){
        for(const field of section.fields){
          const attribute = models["userModels"]["basic"].attributes.filter((atrr) => atrr.name === field).map(attr => ({ ...attr }));
          attribute && attributesModel.push(...attribute)
        }
      }
    }

    if (models["userModels"]["basic"]["fieldsLabels"][modelLabels]) {
      for (const objLabels of models["userModels"]["basic"]["fieldsLabels"][modelLabels]) {
        const matchingAttribute = attributesModel.find(atrr => atrr.name === objLabels.name);
        matchingAttribute && (matchingAttribute.label = objLabels.label);
      }
    }

    setFields(attributesModel);
    setForm(activeModel.form);
    return models;
  };

  useEffect(() => {
    setModels();
    if (userData?.userData?.firstname === null || userData?.userData?.lastname === null) {
      setIsSingUp(true);
    }
  }, []);

  const onChangeField = (field, value, fullValues) => {
    if( field === "l1" && (value !== "" || value !== null)){
      setModels(fullValues.l1)
    }
    setPrevBind(fullValues)
  }

  const redirectStepByStepCompany = async (ids) => {
    let args = {
      userMod: true,
    };
    await refreshUserData();

    // Check if the first during singUp
    let sendTo;
    if (!isSingUp && false) { // todo sandbox
      sendTo = HOME;
    } else {
      sendTo = USER_ACTIVATE;
    }
    history.push({
      pathname: sendTo,
      state: args,
    });
  };


  return (
    <BaseBasicForm text={t("stepByStep.user.titleLeft.register")} leftIcon={leftIcon} showOriginoImage="true">
      <Box position={'absolute'} top={20} right={40} sx={{
        right: { lg: 65, xl: 70 }, 
        display: 'flex', 
        justifyContent: 'flex-end', 
        width: '100%',
      }}>
        <LanguageSelect />
      </Box>
      <BasicFormStepByStep
        formMode={'vertical'}
        saveFunc={() => { }}
        show={true}
        showStepTitle={false}
        showSectionTitle={false}
        sectionAccordionMode={false}
        fieldColSize={12}
        onCanceled={() => { }}
        onUpdated={redirectStepByStepCompany}
        withDefault={false}
        mode={'update'}
        pk={userData.userData.id}
        pkObjectType={1}
        pkReferer={null}
        globalModel={0}
        objectType={'users'}
        bindNullWithDefault={process.env.REACT_APP_SANDBOX_AUTOCOMPLETE === 'true' ? true : false}
        autocompleteSandboxMode={process.env.REACT_APP_SANDBOX_AUTOCOMPLETE === 'true' ? true : false}
        onChangeField={(field, value, fullValues) => {
          onChangeField(field, value, fullValues);
        }}
        activePrevBind={activePrevBind}
        prevBind={prevBind}
        objectTypeBindForm={'users'}
        parentType={'root'}
        parentTypeSubType={1}
        parentId={0}
        count={0}
        fields={fields}
        form={form}
        submitButtonText={t("stepByStep.button.submit")}
        showCancelButton={false}
        cancelButtontext={t("stepByStep.button.cancel")}
        submitOnlyLastStep={true}
      />
    </BaseBasicForm>
  );
}

export default StepByStepRegistroUserEdit;
